var ni = function(e, t, r) {
  if (r || arguments.length === 2) for (var n = 0, s = t.length, i; n < s; n++)
    (i || !(n in t)) && (i || (i = Array.prototype.slice.call(t, 0, n)), i[n] = t[n]);
  return e.concat(i || Array.prototype.slice.call(t));
};
function ai(e) {
  return e;
}
function Y(e, t, r, n, s, i, o, c, u) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return function() {
        return t(e.apply(this, arguments));
      };
    case 3:
      return function() {
        return r(t(e.apply(this, arguments)));
      };
    case 4:
      return function() {
        return n(r(t(e.apply(this, arguments))));
      };
    case 5:
      return function() {
        return s(n(r(t(e.apply(this, arguments)))));
      };
    case 6:
      return function() {
        return i(s(n(r(t(e.apply(this, arguments))))));
      };
    case 7:
      return function() {
        return o(i(s(n(r(t(e.apply(this, arguments)))))));
      };
    case 8:
      return function() {
        return c(o(i(s(n(r(t(e.apply(this, arguments))))))));
      };
    case 9:
      return function() {
        return u(c(o(i(s(n(r(t(e.apply(this, arguments)))))))));
      };
  }
}
function y(e, t, r, n, s, i, o, c, u) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return t(e);
    case 3:
      return r(t(e));
    case 4:
      return n(r(t(e)));
    case 5:
      return s(n(r(t(e))));
    case 6:
      return i(s(n(r(t(e)))));
    case 7:
      return o(i(s(n(r(t(e))))));
    case 8:
      return c(o(i(s(n(r(t(e)))))));
    case 9:
      return u(c(o(i(s(n(r(t(e))))))));
    default: {
      for (var l = arguments[0], f = 1; f < arguments.length; f++)
        l = arguments[f](l);
      return l;
    }
  }
}
var si = function(e, t) {
  var r = function(n) {
    return n.length >= e;
  };
  return function() {
    var n = Array.from(arguments);
    return r(arguments) ? t.apply(this, n) : function(s) {
      return t.apply(void 0, ni([s], n, !1));
    };
  };
}, ii = function(e) {
  return {
    equals: function(t, r) {
      return t === r || e(t, r);
    }
  };
}, oi = function(e) {
  return ii(function(t, r) {
    for (var n in e)
      if (!e[n].equals(t[n], r[n]))
        return !1;
    return !0;
  });
}, Yn = function(e) {
  return e._tag === "Some";
}, Xn = { _tag: "None" }, ea = function(e) {
  return { _tag: "Some", value: e };
}, ci = function(e) {
  return e._tag === "Left";
}, ui = function(e) {
  return e._tag === "Right";
}, li = function(e) {
  return { _tag: "Left", left: e };
}, di = function(e) {
  return { _tag: "Right", right: e };
}, fi = function(e) {
  return [e];
}, pi = [], hi = Object.prototype.hasOwnProperty, vi = function(e) {
  return function(t) {
    return function(r) {
      return r.reduce(function(n, s) {
        return e.concat(n, s);
      }, t);
    };
  };
}, mi = function(e) {
  return {
    concat: function(t, r) {
      var n = {};
      for (var s in e)
        hi.call(e, s) && (n[s] = e[s].concat(t[s], r[s]));
      return n;
    }
  };
}, ir = function() {
  return { concat: ai };
}, gi = function() {
  return { concat: function(e, t) {
    return t;
  } };
}, yi = vi, rn = function(e, t, r) {
  if (r || arguments.length === 2) for (var n = 0, s = t.length, i; n < s; n++)
    (i || !(n in t)) && (i || (i = Array.prototype.slice.call(t, 0, n)), i[n] = t[n]);
  return e.concat(i || Array.prototype.slice.call(t));
}, _i = pi, bi = function(e, t) {
  return e < 0 || e >= t.length;
}, Ti = function(e) {
  return function(t) {
    return rn(rn([], t, !0), [e], !1);
  };
}, $i = Ti, Ai = fi, Q = {
  equals: function(e, t) {
    return e === t;
  }
}, Si = {
  concat: function(e, t) {
    return e + t;
  }
}, Ei = "", wr = {
  concat: Si.concat,
  empty: Ei
};
Q.equals;
var ta = function(e) {
  return e.trim();
}, ra = function(e) {
  return e.length === 0;
}, yt = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function na(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function wi() {
  this.__data__ = [], this.size = 0;
}
var xi = wi;
function ki(e, t) {
  return e === t || e !== e && t !== t;
}
var xr = ki, ji = xr;
function Ri(e, t) {
  for (var r = e.length; r--; )
    if (ji(e[r][0], t))
      return r;
  return -1;
}
var Vt = Ri, Oi = Vt, Ci = Array.prototype, Ii = Ci.splice;
function Mi(e) {
  var t = this.__data__, r = Oi(t, e);
  if (r < 0)
    return !1;
  var n = t.length - 1;
  return r == n ? t.pop() : Ii.call(t, r, 1), --this.size, !0;
}
var Ni = Mi, Pi = Vt;
function Vi(e) {
  var t = this.__data__, r = Pi(t, e);
  return r < 0 ? void 0 : t[r][1];
}
var Di = Vi, Li = Vt;
function Ui(e) {
  return Li(this.__data__, e) > -1;
}
var Zi = Ui, Hi = Vt;
function qi(e, t) {
  var r = this.__data__, n = Hi(r, e);
  return n < 0 ? (++this.size, r.push([e, t])) : r[n][1] = t, this;
}
var Bi = qi, Gi = xi, zi = Ni, Fi = Di, Ki = Zi, Wi = Bi;
function Ee(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
Ee.prototype.clear = Gi;
Ee.prototype.delete = zi;
Ee.prototype.get = Fi;
Ee.prototype.has = Ki;
Ee.prototype.set = Wi;
var Dt = Ee, Qi = Dt;
function Ji() {
  this.__data__ = new Qi(), this.size = 0;
}
var Yi = Ji;
function Xi(e) {
  var t = this.__data__, r = t.delete(e);
  return this.size = t.size, r;
}
var eo = Xi;
function to(e) {
  return this.__data__.get(e);
}
var ro = to;
function no(e) {
  return this.__data__.has(e);
}
var ao = no, so = typeof yt == "object" && yt && yt.Object === Object && yt, aa = so, io = aa, oo = typeof self == "object" && self && self.Object === Object && self, co = io || oo || Function("return this")(), F = co, uo = F, lo = uo.Symbol, Lt = lo, nn = Lt, sa = Object.prototype, fo = sa.hasOwnProperty, po = sa.toString, Le = nn ? nn.toStringTag : void 0;
function ho(e) {
  var t = fo.call(e, Le), r = e[Le];
  try {
    e[Le] = void 0;
    var n = !0;
  } catch {
  }
  var s = po.call(e);
  return n && (t ? e[Le] = r : delete e[Le]), s;
}
var vo = ho, mo = Object.prototype, go = mo.toString;
function yo(e) {
  return go.call(e);
}
var _o = yo, an = Lt, bo = vo, To = _o, $o = "[object Null]", Ao = "[object Undefined]", sn = an ? an.toStringTag : void 0;
function So(e) {
  return e == null ? e === void 0 ? Ao : $o : sn && sn in Object(e) ? bo(e) : To(e);
}
var Ut = So;
function Eo(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var nt = Eo, wo = Ut, xo = nt, ko = "[object AsyncFunction]", jo = "[object Function]", Ro = "[object GeneratorFunction]", Oo = "[object Proxy]";
function Co(e) {
  if (!xo(e))
    return !1;
  var t = wo(e);
  return t == jo || t == Ro || t == ko || t == Oo;
}
var ia = Co, Io = F, Mo = Io["__core-js_shared__"], No = Mo, or = No, on = function() {
  var e = /[^.]+$/.exec(or && or.keys && or.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function Po(e) {
  return !!on && on in e;
}
var Vo = Po, Do = Function.prototype, Lo = Do.toString;
function Uo(e) {
  if (e != null) {
    try {
      return Lo.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var oa = Uo, Zo = ia, Ho = Vo, qo = nt, Bo = oa, Go = /[\\^$.*+?()[\]{}|]/g, zo = /^\[object .+?Constructor\]$/, Fo = Function.prototype, Ko = Object.prototype, Wo = Fo.toString, Qo = Ko.hasOwnProperty, Jo = RegExp(
  "^" + Wo.call(Qo).replace(Go, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function Yo(e) {
  if (!qo(e) || Ho(e))
    return !1;
  var t = Zo(e) ? Jo : zo;
  return t.test(Bo(e));
}
var Xo = Yo;
function ec(e, t) {
  return e == null ? void 0 : e[t];
}
var tc = ec, rc = Xo, nc = tc;
function ac(e, t) {
  var r = nc(e, t);
  return rc(r) ? r : void 0;
}
var de = ac, sc = de, ic = F, oc = sc(ic, "Map"), kr = oc, cc = de, uc = cc(Object, "create"), Zt = uc, cn = Zt;
function lc() {
  this.__data__ = cn ? cn(null) : {}, this.size = 0;
}
var dc = lc;
function fc(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var pc = fc, hc = Zt, vc = "__lodash_hash_undefined__", mc = Object.prototype, gc = mc.hasOwnProperty;
function yc(e) {
  var t = this.__data__;
  if (hc) {
    var r = t[e];
    return r === vc ? void 0 : r;
  }
  return gc.call(t, e) ? t[e] : void 0;
}
var _c = yc, bc = Zt, Tc = Object.prototype, $c = Tc.hasOwnProperty;
function Ac(e) {
  var t = this.__data__;
  return bc ? t[e] !== void 0 : $c.call(t, e);
}
var Sc = Ac, Ec = Zt, wc = "__lodash_hash_undefined__";
function xc(e, t) {
  var r = this.__data__;
  return this.size += this.has(e) ? 0 : 1, r[e] = Ec && t === void 0 ? wc : t, this;
}
var kc = xc, jc = dc, Rc = pc, Oc = _c, Cc = Sc, Ic = kc;
function we(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
we.prototype.clear = jc;
we.prototype.delete = Rc;
we.prototype.get = Oc;
we.prototype.has = Cc;
we.prototype.set = Ic;
var Mc = we, un = Mc, Nc = Dt, Pc = kr;
function Vc() {
  this.size = 0, this.__data__ = {
    hash: new un(),
    map: new (Pc || Nc)(),
    string: new un()
  };
}
var Dc = Vc;
function Lc(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
var Uc = Lc, Zc = Uc;
function Hc(e, t) {
  var r = e.__data__;
  return Zc(t) ? r[typeof t == "string" ? "string" : "hash"] : r.map;
}
var Ht = Hc, qc = Ht;
function Bc(e) {
  var t = qc(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
var Gc = Bc, zc = Ht;
function Fc(e) {
  return zc(this, e).get(e);
}
var Kc = Fc, Wc = Ht;
function Qc(e) {
  return Wc(this, e).has(e);
}
var Jc = Qc, Yc = Ht;
function Xc(e, t) {
  var r = Yc(this, e), n = r.size;
  return r.set(e, t), this.size += r.size == n ? 0 : 1, this;
}
var eu = Xc, tu = Dc, ru = Gc, nu = Kc, au = Jc, su = eu;
function xe(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
xe.prototype.clear = tu;
xe.prototype.delete = ru;
xe.prototype.get = nu;
xe.prototype.has = au;
xe.prototype.set = su;
var ca = xe, iu = Dt, ou = kr, cu = ca, uu = 200;
function lu(e, t) {
  var r = this.__data__;
  if (r instanceof iu) {
    var n = r.__data__;
    if (!ou || n.length < uu - 1)
      return n.push([e, t]), this.size = ++r.size, this;
    r = this.__data__ = new cu(n);
  }
  return r.set(e, t), this.size = r.size, this;
}
var du = lu, fu = Dt, pu = Yi, hu = eo, vu = ro, mu = ao, gu = du;
function ke(e) {
  var t = this.__data__ = new fu(e);
  this.size = t.size;
}
ke.prototype.clear = pu;
ke.prototype.delete = hu;
ke.prototype.get = vu;
ke.prototype.has = mu;
ke.prototype.set = gu;
var ua = ke;
function yu(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n && t(e[r], r, e) !== !1; )
    ;
  return e;
}
var _u = yu, bu = de, Tu = function() {
  try {
    var e = bu(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), $u = Tu, ln = $u;
function Au(e, t, r) {
  t == "__proto__" && ln ? ln(e, t, {
    configurable: !0,
    enumerable: !0,
    value: r,
    writable: !0
  }) : e[t] = r;
}
var la = Au, Su = la, Eu = xr, wu = Object.prototype, xu = wu.hasOwnProperty;
function ku(e, t, r) {
  var n = e[t];
  (!(xu.call(e, t) && Eu(n, r)) || r === void 0 && !(t in e)) && Su(e, t, r);
}
var da = ku, ju = da, Ru = la;
function Ou(e, t, r, n) {
  var s = !r;
  r || (r = {});
  for (var i = -1, o = t.length; ++i < o; ) {
    var c = t[i], u = n ? n(r[c], e[c], c, r, e) : void 0;
    u === void 0 && (u = e[c]), s ? Ru(r, c, u) : ju(r, c, u);
  }
  return r;
}
var qt = Ou;
function Cu(e, t) {
  for (var r = -1, n = Array(e); ++r < e; )
    n[r] = t(r);
  return n;
}
var Iu = Cu;
function Mu(e) {
  return e != null && typeof e == "object";
}
var je = Mu, Nu = Ut, Pu = je, Vu = "[object Arguments]";
function Du(e) {
  return Pu(e) && Nu(e) == Vu;
}
var Lu = Du, dn = Lu, Uu = je, fa = Object.prototype, Zu = fa.hasOwnProperty, Hu = fa.propertyIsEnumerable, qu = dn(/* @__PURE__ */ function() {
  return arguments;
}()) ? dn : function(e) {
  return Uu(e) && Zu.call(e, "callee") && !Hu.call(e, "callee");
}, Bu = qu, Gu = Array.isArray, Bt = Gu, $t = { exports: {} };
function zu() {
  return !1;
}
var Fu = zu;
$t.exports;
(function(e, t) {
  var r = F, n = Fu, s = t && !t.nodeType && t, i = s && !0 && e && !e.nodeType && e, o = i && i.exports === s, c = o ? r.Buffer : void 0, u = c ? c.isBuffer : void 0, l = u || n;
  e.exports = l;
})($t, $t.exports);
var jr = $t.exports, Ku = 9007199254740991, Wu = /^(?:0|[1-9]\d*)$/;
function Qu(e, t) {
  var r = typeof e;
  return t = t ?? Ku, !!t && (r == "number" || r != "symbol" && Wu.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
var Ju = Qu, Yu = 9007199254740991;
function Xu(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= Yu;
}
var pa = Xu, el = Ut, tl = pa, rl = je, nl = "[object Arguments]", al = "[object Array]", sl = "[object Boolean]", il = "[object Date]", ol = "[object Error]", cl = "[object Function]", ul = "[object Map]", ll = "[object Number]", dl = "[object Object]", fl = "[object RegExp]", pl = "[object Set]", hl = "[object String]", vl = "[object WeakMap]", ml = "[object ArrayBuffer]", gl = "[object DataView]", yl = "[object Float32Array]", _l = "[object Float64Array]", bl = "[object Int8Array]", Tl = "[object Int16Array]", $l = "[object Int32Array]", Al = "[object Uint8Array]", Sl = "[object Uint8ClampedArray]", El = "[object Uint16Array]", wl = "[object Uint32Array]", w = {};
w[yl] = w[_l] = w[bl] = w[Tl] = w[$l] = w[Al] = w[Sl] = w[El] = w[wl] = !0;
w[nl] = w[al] = w[ml] = w[sl] = w[gl] = w[il] = w[ol] = w[cl] = w[ul] = w[ll] = w[dl] = w[fl] = w[pl] = w[hl] = w[vl] = !1;
function xl(e) {
  return rl(e) && tl(e.length) && !!w[el(e)];
}
var kl = xl;
function jl(e) {
  return function(t) {
    return e(t);
  };
}
var Rr = jl, At = { exports: {} };
At.exports;
(function(e, t) {
  var r = aa, n = t && !t.nodeType && t, s = n && !0 && e && !e.nodeType && e, i = s && s.exports === n, o = i && r.process, c = function() {
    try {
      var u = s && s.require && s.require("util").types;
      return u || o && o.binding && o.binding("util");
    } catch {
    }
  }();
  e.exports = c;
})(At, At.exports);
var Or = At.exports, Rl = kl, Ol = Rr, fn = Or, pn = fn && fn.isTypedArray, Cl = pn ? Ol(pn) : Rl, ha = Cl, Il = Iu, Ml = Bu, Nl = Bt, Pl = jr, Vl = Ju, Dl = ha, Ll = Object.prototype, Ul = Ll.hasOwnProperty;
function Zl(e, t) {
  var r = Nl(e), n = !r && Ml(e), s = !r && !n && Pl(e), i = !r && !n && !s && Dl(e), o = r || n || s || i, c = o ? Il(e.length, String) : [], u = c.length;
  for (var l in e)
    (t || Ul.call(e, l)) && !(o && // Safari 9 has enumerable `arguments.length` in strict mode.
    (l == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    s && (l == "offset" || l == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    i && (l == "buffer" || l == "byteLength" || l == "byteOffset") || // Skip index properties.
    Vl(l, u))) && c.push(l);
  return c;
}
var va = Zl, Hl = Object.prototype;
function ql(e) {
  var t = e && e.constructor, r = typeof t == "function" && t.prototype || Hl;
  return e === r;
}
var Cr = ql;
function Bl(e, t) {
  return function(r) {
    return e(t(r));
  };
}
var ma = Bl, Gl = ma, zl = Gl(Object.keys, Object), Fl = zl, Kl = Cr, Wl = Fl, Ql = Object.prototype, Jl = Ql.hasOwnProperty;
function Yl(e) {
  if (!Kl(e))
    return Wl(e);
  var t = [];
  for (var r in Object(e))
    Jl.call(e, r) && r != "constructor" && t.push(r);
  return t;
}
var Xl = Yl, ed = ia, td = pa;
function rd(e) {
  return e != null && td(e.length) && !ed(e);
}
var ga = rd, nd = va, ad = Xl, sd = ga;
function id(e) {
  return sd(e) ? nd(e) : ad(e);
}
var Ir = id, od = qt, cd = Ir;
function ud(e, t) {
  return e && od(t, cd(t), e);
}
var ld = ud;
function dd(e) {
  var t = [];
  if (e != null)
    for (var r in Object(e))
      t.push(r);
  return t;
}
var fd = dd, pd = nt, hd = Cr, vd = fd, md = Object.prototype, gd = md.hasOwnProperty;
function yd(e) {
  if (!pd(e))
    return vd(e);
  var t = hd(e), r = [];
  for (var n in e)
    n == "constructor" && (t || !gd.call(e, n)) || r.push(n);
  return r;
}
var _d = yd, bd = va, Td = _d, $d = ga;
function Ad(e) {
  return $d(e) ? bd(e, !0) : Td(e);
}
var Mr = Ad, Sd = qt, Ed = Mr;
function wd(e, t) {
  return e && Sd(t, Ed(t), e);
}
var xd = wd, St = { exports: {} };
St.exports;
(function(e, t) {
  var r = F, n = t && !t.nodeType && t, s = n && !0 && e && !e.nodeType && e, i = s && s.exports === n, o = i ? r.Buffer : void 0, c = o ? o.allocUnsafe : void 0;
  function u(l, f) {
    if (f)
      return l.slice();
    var _ = l.length, A = c ? c(_) : new l.constructor(_);
    return l.copy(A), A;
  }
  e.exports = u;
})(St, St.exports);
var kd = St.exports;
function jd(e, t) {
  var r = -1, n = e.length;
  for (t || (t = Array(n)); ++r < n; )
    t[r] = e[r];
  return t;
}
var Rd = jd;
function Od(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length, s = 0, i = []; ++r < n; ) {
    var o = e[r];
    t(o, r, e) && (i[s++] = o);
  }
  return i;
}
var Cd = Od;
function Id() {
  return [];
}
var ya = Id, Md = Cd, Nd = ya, Pd = Object.prototype, Vd = Pd.propertyIsEnumerable, hn = Object.getOwnPropertySymbols, Dd = hn ? function(e) {
  return e == null ? [] : (e = Object(e), Md(hn(e), function(t) {
    return Vd.call(e, t);
  }));
} : Nd, Nr = Dd, Ld = qt, Ud = Nr;
function Zd(e, t) {
  return Ld(e, Ud(e), t);
}
var Hd = Zd;
function qd(e, t) {
  for (var r = -1, n = t.length, s = e.length; ++r < n; )
    e[s + r] = t[r];
  return e;
}
var _a = qd, Bd = ma, Gd = Bd(Object.getPrototypeOf, Object), ba = Gd, zd = _a, Fd = ba, Kd = Nr, Wd = ya, Qd = Object.getOwnPropertySymbols, Jd = Qd ? function(e) {
  for (var t = []; e; )
    zd(t, Kd(e)), e = Fd(e);
  return t;
} : Wd, Ta = Jd, Yd = qt, Xd = Ta;
function ef(e, t) {
  return Yd(e, Xd(e), t);
}
var tf = ef, rf = _a, nf = Bt;
function af(e, t, r) {
  var n = t(e);
  return nf(e) ? n : rf(n, r(e));
}
var $a = af, sf = $a, of = Nr, cf = Ir;
function uf(e) {
  return sf(e, cf, of);
}
var Aa = uf, lf = $a, df = Ta, ff = Mr;
function pf(e) {
  return lf(e, ff, df);
}
var hf = pf, vf = de, mf = F, gf = vf(mf, "DataView"), yf = gf, _f = de, bf = F, Tf = _f(bf, "Promise"), $f = Tf, Af = de, Sf = F, Ef = Af(Sf, "Set"), wf = Ef, xf = de, kf = F, jf = xf(kf, "WeakMap"), Rf = jf, pr = yf, hr = kr, vr = $f, mr = wf, gr = Rf, Sa = Ut, Re = oa, vn = "[object Map]", Of = "[object Object]", mn = "[object Promise]", gn = "[object Set]", yn = "[object WeakMap]", _n = "[object DataView]", Cf = Re(pr), If = Re(hr), Mf = Re(vr), Nf = Re(mr), Pf = Re(gr), oe = Sa;
(pr && oe(new pr(new ArrayBuffer(1))) != _n || hr && oe(new hr()) != vn || vr && oe(vr.resolve()) != mn || mr && oe(new mr()) != gn || gr && oe(new gr()) != yn) && (oe = function(e) {
  var t = Sa(e), r = t == Of ? e.constructor : void 0, n = r ? Re(r) : "";
  if (n)
    switch (n) {
      case Cf:
        return _n;
      case If:
        return vn;
      case Mf:
        return mn;
      case Nf:
        return gn;
      case Pf:
        return yn;
    }
  return t;
});
var Gt = oe, Vf = Object.prototype, Df = Vf.hasOwnProperty;
function Lf(e) {
  var t = e.length, r = new e.constructor(t);
  return t && typeof e[0] == "string" && Df.call(e, "index") && (r.index = e.index, r.input = e.input), r;
}
var Uf = Lf, Zf = F, Hf = Zf.Uint8Array, Ea = Hf, bn = Ea;
function qf(e) {
  var t = new e.constructor(e.byteLength);
  return new bn(t).set(new bn(e)), t;
}
var Pr = qf, Bf = Pr;
function Gf(e, t) {
  var r = t ? Bf(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.byteLength);
}
var zf = Gf, Ff = /\w*$/;
function Kf(e) {
  var t = new e.constructor(e.source, Ff.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var Wf = Kf, Tn = Lt, $n = Tn ? Tn.prototype : void 0, An = $n ? $n.valueOf : void 0;
function Qf(e) {
  return An ? Object(An.call(e)) : {};
}
var Jf = Qf, Yf = Pr;
function Xf(e, t) {
  var r = t ? Yf(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.length);
}
var ep = Xf, tp = Pr, rp = zf, np = Wf, ap = Jf, sp = ep, ip = "[object Boolean]", op = "[object Date]", cp = "[object Map]", up = "[object Number]", lp = "[object RegExp]", dp = "[object Set]", fp = "[object String]", pp = "[object Symbol]", hp = "[object ArrayBuffer]", vp = "[object DataView]", mp = "[object Float32Array]", gp = "[object Float64Array]", yp = "[object Int8Array]", _p = "[object Int16Array]", bp = "[object Int32Array]", Tp = "[object Uint8Array]", $p = "[object Uint8ClampedArray]", Ap = "[object Uint16Array]", Sp = "[object Uint32Array]";
function Ep(e, t, r) {
  var n = e.constructor;
  switch (t) {
    case hp:
      return tp(e);
    case ip:
    case op:
      return new n(+e);
    case vp:
      return rp(e, r);
    case mp:
    case gp:
    case yp:
    case _p:
    case bp:
    case Tp:
    case $p:
    case Ap:
    case Sp:
      return sp(e, r);
    case cp:
      return new n();
    case up:
    case fp:
      return new n(e);
    case lp:
      return np(e);
    case dp:
      return new n();
    case pp:
      return ap(e);
  }
}
var wp = Ep, xp = nt, Sn = Object.create, kp = /* @__PURE__ */ function() {
  function e() {
  }
  return function(t) {
    if (!xp(t))
      return {};
    if (Sn)
      return Sn(t);
    e.prototype = t;
    var r = new e();
    return e.prototype = void 0, r;
  };
}(), jp = kp, Rp = jp, Op = ba, Cp = Cr;
function Ip(e) {
  return typeof e.constructor == "function" && !Cp(e) ? Rp(Op(e)) : {};
}
var Mp = Ip, Np = Gt, Pp = je, Vp = "[object Map]";
function Dp(e) {
  return Pp(e) && Np(e) == Vp;
}
var Lp = Dp, Up = Lp, Zp = Rr, En = Or, wn = En && En.isMap, Hp = wn ? Zp(wn) : Up, qp = Hp, Bp = Gt, Gp = je, zp = "[object Set]";
function Fp(e) {
  return Gp(e) && Bp(e) == zp;
}
var Kp = Fp, Wp = Kp, Qp = Rr, xn = Or, kn = xn && xn.isSet, Jp = kn ? Qp(kn) : Wp, Yp = Jp, Xp = ua, eh = _u, th = da, rh = ld, nh = xd, ah = kd, sh = Rd, ih = Hd, oh = tf, ch = Aa, uh = hf, lh = Gt, dh = Uf, fh = wp, ph = Mp, hh = Bt, vh = jr, mh = qp, gh = nt, yh = Yp, _h = Ir, bh = Mr, Th = 1, $h = 2, Ah = 4, wa = "[object Arguments]", Sh = "[object Array]", Eh = "[object Boolean]", wh = "[object Date]", xh = "[object Error]", xa = "[object Function]", kh = "[object GeneratorFunction]", jh = "[object Map]", Rh = "[object Number]", ka = "[object Object]", Oh = "[object RegExp]", Ch = "[object Set]", Ih = "[object String]", Mh = "[object Symbol]", Nh = "[object WeakMap]", Ph = "[object ArrayBuffer]", Vh = "[object DataView]", Dh = "[object Float32Array]", Lh = "[object Float64Array]", Uh = "[object Int8Array]", Zh = "[object Int16Array]", Hh = "[object Int32Array]", qh = "[object Uint8Array]", Bh = "[object Uint8ClampedArray]", Gh = "[object Uint16Array]", zh = "[object Uint32Array]", E = {};
E[wa] = E[Sh] = E[Ph] = E[Vh] = E[Eh] = E[wh] = E[Dh] = E[Lh] = E[Uh] = E[Zh] = E[Hh] = E[jh] = E[Rh] = E[ka] = E[Oh] = E[Ch] = E[Ih] = E[Mh] = E[qh] = E[Bh] = E[Gh] = E[zh] = !0;
E[xh] = E[xa] = E[Nh] = !1;
function Tt(e, t, r, n, s, i) {
  var o, c = t & Th, u = t & $h, l = t & Ah;
  if (r && (o = s ? r(e, n, s, i) : r(e)), o !== void 0)
    return o;
  if (!gh(e))
    return e;
  var f = hh(e);
  if (f) {
    if (o = dh(e), !c)
      return sh(e, o);
  } else {
    var _ = lh(e), A = _ == xa || _ == kh;
    if (vh(e))
      return ah(e, c);
    if (_ == ka || _ == wa || A && !s) {
      if (o = u || A ? {} : ph(e), !c)
        return u ? oh(e, nh(o, e)) : ih(e, rh(o, e));
    } else {
      if (!E[_])
        return s ? e : {};
      o = fh(e, _, c);
    }
  }
  i || (i = new Xp());
  var I = i.get(e);
  if (I)
    return I;
  i.set(e, o), yh(e) ? e.forEach(function(O) {
    o.add(Tt(O, t, r, O, e, i));
  }) : mh(e) && e.forEach(function(O, N) {
    o.set(N, Tt(O, t, r, N, e, i));
  });
  var V = l ? u ? uh : ch : u ? bh : _h, D = f ? void 0 : V(e);
  return eh(D || e, function(O, N) {
    D && (N = O, O = e[N]), th(o, N, Tt(O, t, r, N, e, i));
  }), o;
}
var Fh = Tt, Kh = Fh, Wh = 1, Qh = 4;
function Jh(e) {
  return Kh(e, Wh | Qh);
}
var Yh = Jh;
const Xh = /* @__PURE__ */ na(Yh);
var $;
(function(e) {
  e.assertEqual = (s) => s;
  function t(s) {
  }
  e.assertIs = t;
  function r(s) {
    throw new Error();
  }
  e.assertNever = r, e.arrayToEnum = (s) => {
    const i = {};
    for (const o of s)
      i[o] = o;
    return i;
  }, e.getValidEnumValues = (s) => {
    const i = e.objectKeys(s).filter((c) => typeof s[s[c]] != "number"), o = {};
    for (const c of i)
      o[c] = s[c];
    return e.objectValues(o);
  }, e.objectValues = (s) => e.objectKeys(s).map(function(i) {
    return s[i];
  }), e.objectKeys = typeof Object.keys == "function" ? (s) => Object.keys(s) : (s) => {
    const i = [];
    for (const o in s)
      Object.prototype.hasOwnProperty.call(s, o) && i.push(o);
    return i;
  }, e.find = (s, i) => {
    for (const o of s)
      if (i(o))
        return o;
  }, e.isInteger = typeof Number.isInteger == "function" ? (s) => Number.isInteger(s) : (s) => typeof s == "number" && isFinite(s) && Math.floor(s) === s;
  function n(s, i = " | ") {
    return s.map((o) => typeof o == "string" ? `'${o}'` : o).join(i);
  }
  e.joinValues = n, e.jsonStringifyReplacer = (s, i) => typeof i == "bigint" ? i.toString() : i;
})($ || ($ = {}));
var yr;
(function(e) {
  e.mergeShapes = (t, r) => ({
    ...t,
    ...r
    // second overwrites first
  });
})(yr || (yr = {}));
const h = $.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]), J = (e) => {
  switch (typeof e) {
    case "undefined":
      return h.undefined;
    case "string":
      return h.string;
    case "number":
      return isNaN(e) ? h.nan : h.number;
    case "boolean":
      return h.boolean;
    case "function":
      return h.function;
    case "bigint":
      return h.bigint;
    case "symbol":
      return h.symbol;
    case "object":
      return Array.isArray(e) ? h.array : e === null ? h.null : e.then && typeof e.then == "function" && e.catch && typeof e.catch == "function" ? h.promise : typeof Map < "u" && e instanceof Map ? h.map : typeof Set < "u" && e instanceof Set ? h.set : typeof Date < "u" && e instanceof Date ? h.date : h.object;
    default:
      return h.unknown;
  }
}, d = $.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]), ev = (e) => JSON.stringify(e, null, 2).replace(/"([^"]+)":/g, "$1:");
class L extends Error {
  constructor(t) {
    super(), this.issues = [], this.addIssue = (n) => {
      this.issues = [...this.issues, n];
    }, this.addIssues = (n = []) => {
      this.issues = [...this.issues, ...n];
    };
    const r = new.target.prototype;
    Object.setPrototypeOf ? Object.setPrototypeOf(this, r) : this.__proto__ = r, this.name = "ZodError", this.issues = t;
  }
  get errors() {
    return this.issues;
  }
  format(t) {
    const r = t || function(i) {
      return i.message;
    }, n = { _errors: [] }, s = (i) => {
      for (const o of i.issues)
        if (o.code === "invalid_union")
          o.unionErrors.map(s);
        else if (o.code === "invalid_return_type")
          s(o.returnTypeError);
        else if (o.code === "invalid_arguments")
          s(o.argumentsError);
        else if (o.path.length === 0)
          n._errors.push(r(o));
        else {
          let c = n, u = 0;
          for (; u < o.path.length; ) {
            const l = o.path[u];
            u === o.path.length - 1 ? (c[l] = c[l] || { _errors: [] }, c[l]._errors.push(r(o))) : c[l] = c[l] || { _errors: [] }, c = c[l], u++;
          }
        }
    };
    return s(this), n;
  }
  static assert(t) {
    if (!(t instanceof L))
      throw new Error(`Not a ZodError: ${t}`);
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, $.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(t = (r) => r.message) {
    const r = {}, n = [];
    for (const s of this.issues)
      s.path.length > 0 ? (r[s.path[0]] = r[s.path[0]] || [], r[s.path[0]].push(t(s))) : n.push(t(s));
    return { formErrors: n, fieldErrors: r };
  }
  get formErrors() {
    return this.flatten();
  }
}
L.create = (e) => new L(e);
const ye = (e, t) => {
  let r;
  switch (e.code) {
    case d.invalid_type:
      e.received === h.undefined ? r = "Required" : r = `Expected ${e.expected}, received ${e.received}`;
      break;
    case d.invalid_literal:
      r = `Invalid literal value, expected ${JSON.stringify(e.expected, $.jsonStringifyReplacer)}`;
      break;
    case d.unrecognized_keys:
      r = `Unrecognized key(s) in object: ${$.joinValues(e.keys, ", ")}`;
      break;
    case d.invalid_union:
      r = "Invalid input";
      break;
    case d.invalid_union_discriminator:
      r = `Invalid discriminator value. Expected ${$.joinValues(e.options)}`;
      break;
    case d.invalid_enum_value:
      r = `Invalid enum value. Expected ${$.joinValues(e.options)}, received '${e.received}'`;
      break;
    case d.invalid_arguments:
      r = "Invalid function arguments";
      break;
    case d.invalid_return_type:
      r = "Invalid function return type";
      break;
    case d.invalid_date:
      r = "Invalid date";
      break;
    case d.invalid_string:
      typeof e.validation == "object" ? "includes" in e.validation ? (r = `Invalid input: must include "${e.validation.includes}"`, typeof e.validation.position == "number" && (r = `${r} at one or more positions greater than or equal to ${e.validation.position}`)) : "startsWith" in e.validation ? r = `Invalid input: must start with "${e.validation.startsWith}"` : "endsWith" in e.validation ? r = `Invalid input: must end with "${e.validation.endsWith}"` : $.assertNever(e.validation) : e.validation !== "regex" ? r = `Invalid ${e.validation}` : r = "Invalid";
      break;
    case d.too_small:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "more than"} ${e.minimum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "over"} ${e.minimum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${e.minimum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${new Date(Number(e.minimum))}` : r = "Invalid input";
      break;
    case d.too_big:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "less than"} ${e.maximum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "under"} ${e.maximum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "bigint" ? r = `BigInt must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly" : e.inclusive ? "smaller than or equal to" : "smaller than"} ${new Date(Number(e.maximum))}` : r = "Invalid input";
      break;
    case d.custom:
      r = "Invalid input";
      break;
    case d.invalid_intersection_types:
      r = "Intersection results could not be merged";
      break;
    case d.not_multiple_of:
      r = `Number must be a multiple of ${e.multipleOf}`;
      break;
    case d.not_finite:
      r = "Number must be finite";
      break;
    default:
      r = t.defaultError, $.assertNever(e);
  }
  return { message: r };
};
let ja = ye;
function tv(e) {
  ja = e;
}
function Et() {
  return ja;
}
const wt = (e) => {
  const { data: t, path: r, errorMaps: n, issueData: s } = e, i = [...r, ...s.path || []], o = {
    ...s,
    path: i
  };
  if (s.message !== void 0)
    return {
      ...s,
      path: i,
      message: s.message
    };
  let c = "";
  const u = n.filter((l) => !!l).slice().reverse();
  for (const l of u)
    c = l(o, { data: t, defaultError: c }).message;
  return {
    ...s,
    path: i,
    message: c
  };
}, rv = [];
function p(e, t) {
  const r = Et(), n = wt({
    issueData: t,
    data: e.data,
    path: e.path,
    errorMaps: [
      e.common.contextualErrorMap,
      e.schemaErrorMap,
      r,
      r === ye ? void 0 : ye
      // then global default map
    ].filter((s) => !!s)
  });
  e.common.issues.push(n);
}
class M {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    this.value === "valid" && (this.value = "dirty");
  }
  abort() {
    this.value !== "aborted" && (this.value = "aborted");
  }
  static mergeArray(t, r) {
    const n = [];
    for (const s of r) {
      if (s.status === "aborted")
        return g;
      s.status === "dirty" && t.dirty(), n.push(s.value);
    }
    return { status: t.value, value: n };
  }
  static async mergeObjectAsync(t, r) {
    const n = [];
    for (const s of r) {
      const i = await s.key, o = await s.value;
      n.push({
        key: i,
        value: o
      });
    }
    return M.mergeObjectSync(t, n);
  }
  static mergeObjectSync(t, r) {
    const n = {};
    for (const s of r) {
      const { key: i, value: o } = s;
      if (i.status === "aborted" || o.status === "aborted")
        return g;
      i.status === "dirty" && t.dirty(), o.status === "dirty" && t.dirty(), i.value !== "__proto__" && (typeof o.value < "u" || s.alwaysSet) && (n[i.value] = o.value);
    }
    return { status: t.value, value: n };
  }
}
const g = Object.freeze({
  status: "aborted"
}), me = (e) => ({ status: "dirty", value: e }), P = (e) => ({ status: "valid", value: e }), _r = (e) => e.status === "aborted", br = (e) => e.status === "dirty", He = (e) => e.status === "valid", qe = (e) => typeof Promise < "u" && e instanceof Promise;
function xt(e, t, r, n) {
  if (typeof t == "function" ? e !== t || !n : !t.has(e)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return t.get(e);
}
function Ra(e, t, r, n, s) {
  if (typeof t == "function" ? e !== t || !s : !t.has(e)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return t.set(e, r), r;
}
var v;
(function(e) {
  e.errToObj = (t) => typeof t == "string" ? { message: t } : t || {}, e.toString = (t) => typeof t == "string" ? t : t == null ? void 0 : t.message;
})(v || (v = {}));
var Ue, Ze;
class B {
  constructor(t, r, n, s) {
    this._cachedPath = [], this.parent = t, this.data = r, this._path = n, this._key = s;
  }
  get path() {
    return this._cachedPath.length || (this._key instanceof Array ? this._cachedPath.push(...this._path, ...this._key) : this._cachedPath.push(...this._path, this._key)), this._cachedPath;
  }
}
const jn = (e, t) => {
  if (He(t))
    return { success: !0, data: t.value };
  if (!e.common.issues.length)
    throw new Error("Validation failed but no issues detected.");
  return {
    success: !1,
    get error() {
      if (this._error)
        return this._error;
      const r = new L(e.common.issues);
      return this._error = r, this._error;
    }
  };
};
function b(e) {
  if (!e)
    return {};
  const { errorMap: t, invalid_type_error: r, required_error: n, description: s } = e;
  if (t && (r || n))
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  return t ? { errorMap: t, description: s } : { errorMap: (o, c) => {
    var u, l;
    const { message: f } = e;
    return o.code === "invalid_enum_value" ? { message: f ?? c.defaultError } : typeof c.data > "u" ? { message: (u = f ?? n) !== null && u !== void 0 ? u : c.defaultError } : o.code !== "invalid_type" ? { message: c.defaultError } : { message: (l = f ?? r) !== null && l !== void 0 ? l : c.defaultError };
  }, description: s };
}
class T {
  constructor(t) {
    this.spa = this.safeParseAsync, this._def = t, this.parse = this.parse.bind(this), this.safeParse = this.safeParse.bind(this), this.parseAsync = this.parseAsync.bind(this), this.safeParseAsync = this.safeParseAsync.bind(this), this.spa = this.spa.bind(this), this.refine = this.refine.bind(this), this.refinement = this.refinement.bind(this), this.superRefine = this.superRefine.bind(this), this.optional = this.optional.bind(this), this.nullable = this.nullable.bind(this), this.nullish = this.nullish.bind(this), this.array = this.array.bind(this), this.promise = this.promise.bind(this), this.or = this.or.bind(this), this.and = this.and.bind(this), this.transform = this.transform.bind(this), this.brand = this.brand.bind(this), this.default = this.default.bind(this), this.catch = this.catch.bind(this), this.describe = this.describe.bind(this), this.pipe = this.pipe.bind(this), this.readonly = this.readonly.bind(this), this.isNullable = this.isNullable.bind(this), this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(t) {
    return J(t.data);
  }
  _getOrReturnCtx(t, r) {
    return r || {
      common: t.parent.common,
      data: t.data,
      parsedType: J(t.data),
      schemaErrorMap: this._def.errorMap,
      path: t.path,
      parent: t.parent
    };
  }
  _processInputParams(t) {
    return {
      status: new M(),
      ctx: {
        common: t.parent.common,
        data: t.data,
        parsedType: J(t.data),
        schemaErrorMap: this._def.errorMap,
        path: t.path,
        parent: t.parent
      }
    };
  }
  _parseSync(t) {
    const r = this._parse(t);
    if (qe(r))
      throw new Error("Synchronous parse encountered promise.");
    return r;
  }
  _parseAsync(t) {
    const r = this._parse(t);
    return Promise.resolve(r);
  }
  parse(t, r) {
    const n = this.safeParse(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  safeParse(t, r) {
    var n;
    const s = {
      common: {
        issues: [],
        async: (n = r == null ? void 0 : r.async) !== null && n !== void 0 ? n : !1,
        contextualErrorMap: r == null ? void 0 : r.errorMap
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: J(t)
    }, i = this._parseSync({ data: t, path: s.path, parent: s });
    return jn(s, i);
  }
  async parseAsync(t, r) {
    const n = await this.safeParseAsync(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  async safeParseAsync(t, r) {
    const n = {
      common: {
        issues: [],
        contextualErrorMap: r == null ? void 0 : r.errorMap,
        async: !0
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: J(t)
    }, s = this._parse({ data: t, path: n.path, parent: n }), i = await (qe(s) ? s : Promise.resolve(s));
    return jn(n, i);
  }
  refine(t, r) {
    const n = (s) => typeof r == "string" || typeof r > "u" ? { message: r } : typeof r == "function" ? r(s) : r;
    return this._refinement((s, i) => {
      const o = t(s), c = () => i.addIssue({
        code: d.custom,
        ...n(s)
      });
      return typeof Promise < "u" && o instanceof Promise ? o.then((u) => u ? !0 : (c(), !1)) : o ? !0 : (c(), !1);
    });
  }
  refinement(t, r) {
    return this._refinement((n, s) => t(n) ? !0 : (s.addIssue(typeof r == "function" ? r(n, s) : r), !1));
  }
  _refinement(t) {
    return new H({
      schema: this,
      typeName: m.ZodEffects,
      effect: { type: "refinement", refinement: t }
    });
  }
  superRefine(t) {
    return this._refinement(t);
  }
  optional() {
    return q.create(this, this._def);
  }
  nullable() {
    return re.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return Z.create(this, this._def);
  }
  promise() {
    return be.create(this, this._def);
  }
  or(t) {
    return Fe.create([this, t], this._def);
  }
  and(t) {
    return Ke.create(this, t, this._def);
  }
  transform(t) {
    return new H({
      ...b(this._def),
      schema: this,
      typeName: m.ZodEffects,
      effect: { type: "transform", transform: t }
    });
  }
  default(t) {
    const r = typeof t == "function" ? t : () => t;
    return new Xe({
      ...b(this._def),
      innerType: this,
      defaultValue: r,
      typeName: m.ZodDefault
    });
  }
  brand() {
    return new Vr({
      typeName: m.ZodBranded,
      type: this,
      ...b(this._def)
    });
  }
  catch(t) {
    const r = typeof t == "function" ? t : () => t;
    return new et({
      ...b(this._def),
      innerType: this,
      catchValue: r,
      typeName: m.ZodCatch
    });
  }
  describe(t) {
    const r = this.constructor;
    return new r({
      ...this._def,
      description: t
    });
  }
  pipe(t) {
    return at.create(this, t);
  }
  readonly() {
    return tt.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
}
const nv = /^c[^\s-]{8,}$/i, av = /^[0-9a-z]+$/, sv = /^[0-9A-HJKMNP-TV-Z]{26}$/, iv = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i, ov = /^[a-z0-9_-]{21}$/i, cv = /^[-+]?P(?!$)(?:(?:[-+]?\d+Y)|(?:[-+]?\d+[.,]\d+Y$))?(?:(?:[-+]?\d+M)|(?:[-+]?\d+[.,]\d+M$))?(?:(?:[-+]?\d+W)|(?:[-+]?\d+[.,]\d+W$))?(?:(?:[-+]?\d+D)|(?:[-+]?\d+[.,]\d+D$))?(?:T(?=[\d+-])(?:(?:[-+]?\d+H)|(?:[-+]?\d+[.,]\d+H$))?(?:(?:[-+]?\d+M)|(?:[-+]?\d+[.,]\d+M$))?(?:[-+]?\d+(?:[.,]\d+)?S)?)??$/, uv = /^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i, lv = "^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$";
let cr;
const dv = /^(?:(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/, fv = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/, pv = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/, Oa = "((\\d\\d[2468][048]|\\d\\d[13579][26]|\\d\\d0[48]|[02468][048]00|[13579][26]00)-02-29|\\d{4}-((0[13578]|1[02])-(0[1-9]|[12]\\d|3[01])|(0[469]|11)-(0[1-9]|[12]\\d|30)|(02)-(0[1-9]|1\\d|2[0-8])))", hv = new RegExp(`^${Oa}$`);
function Ca(e) {
  let t = "([01]\\d|2[0-3]):[0-5]\\d:[0-5]\\d";
  return e.precision ? t = `${t}\\.\\d{${e.precision}}` : e.precision == null && (t = `${t}(\\.\\d+)?`), t;
}
function vv(e) {
  return new RegExp(`^${Ca(e)}$`);
}
function Ia(e) {
  let t = `${Oa}T${Ca(e)}`;
  const r = [];
  return r.push(e.local ? "Z?" : "Z"), e.offset && r.push("([+-]\\d{2}:?\\d{2})"), t = `${t}(${r.join("|")})`, new RegExp(`^${t}$`);
}
function mv(e, t) {
  return !!((t === "v4" || !t) && dv.test(e) || (t === "v6" || !t) && fv.test(e));
}
class U extends T {
  _parse(t) {
    if (this._def.coerce && (t.data = String(t.data)), this._getType(t) !== h.string) {
      const i = this._getOrReturnCtx(t);
      return p(i, {
        code: d.invalid_type,
        expected: h.string,
        received: i.parsedType
      }), g;
    }
    const n = new M();
    let s;
    for (const i of this._def.checks)
      if (i.kind === "min")
        t.data.length < i.value && (s = this._getOrReturnCtx(t, s), p(s, {
          code: d.too_small,
          minimum: i.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: i.message
        }), n.dirty());
      else if (i.kind === "max")
        t.data.length > i.value && (s = this._getOrReturnCtx(t, s), p(s, {
          code: d.too_big,
          maximum: i.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: i.message
        }), n.dirty());
      else if (i.kind === "length") {
        const o = t.data.length > i.value, c = t.data.length < i.value;
        (o || c) && (s = this._getOrReturnCtx(t, s), o ? p(s, {
          code: d.too_big,
          maximum: i.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: i.message
        }) : c && p(s, {
          code: d.too_small,
          minimum: i.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: i.message
        }), n.dirty());
      } else if (i.kind === "email")
        uv.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "email",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "emoji")
        cr || (cr = new RegExp(lv, "u")), cr.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "emoji",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "uuid")
        iv.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "uuid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "nanoid")
        ov.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "nanoid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "cuid")
        nv.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "cuid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "cuid2")
        av.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "cuid2",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "ulid")
        sv.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
          validation: "ulid",
          code: d.invalid_string,
          message: i.message
        }), n.dirty());
      else if (i.kind === "url")
        try {
          new URL(t.data);
        } catch {
          s = this._getOrReturnCtx(t, s), p(s, {
            validation: "url",
            code: d.invalid_string,
            message: i.message
          }), n.dirty();
        }
      else i.kind === "regex" ? (i.regex.lastIndex = 0, i.regex.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
        validation: "regex",
        code: d.invalid_string,
        message: i.message
      }), n.dirty())) : i.kind === "trim" ? t.data = t.data.trim() : i.kind === "includes" ? t.data.includes(i.value, i.position) || (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.invalid_string,
        validation: { includes: i.value, position: i.position },
        message: i.message
      }), n.dirty()) : i.kind === "toLowerCase" ? t.data = t.data.toLowerCase() : i.kind === "toUpperCase" ? t.data = t.data.toUpperCase() : i.kind === "startsWith" ? t.data.startsWith(i.value) || (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.invalid_string,
        validation: { startsWith: i.value },
        message: i.message
      }), n.dirty()) : i.kind === "endsWith" ? t.data.endsWith(i.value) || (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.invalid_string,
        validation: { endsWith: i.value },
        message: i.message
      }), n.dirty()) : i.kind === "datetime" ? Ia(i).test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.invalid_string,
        validation: "datetime",
        message: i.message
      }), n.dirty()) : i.kind === "date" ? hv.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.invalid_string,
        validation: "date",
        message: i.message
      }), n.dirty()) : i.kind === "time" ? vv(i).test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.invalid_string,
        validation: "time",
        message: i.message
      }), n.dirty()) : i.kind === "duration" ? cv.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
        validation: "duration",
        code: d.invalid_string,
        message: i.message
      }), n.dirty()) : i.kind === "ip" ? mv(t.data, i.version) || (s = this._getOrReturnCtx(t, s), p(s, {
        validation: "ip",
        code: d.invalid_string,
        message: i.message
      }), n.dirty()) : i.kind === "base64" ? pv.test(t.data) || (s = this._getOrReturnCtx(t, s), p(s, {
        validation: "base64",
        code: d.invalid_string,
        message: i.message
      }), n.dirty()) : $.assertNever(i);
    return { status: n.value, value: t.data };
  }
  _regex(t, r, n) {
    return this.refinement((s) => t.test(s), {
      validation: r,
      code: d.invalid_string,
      ...v.errToObj(n)
    });
  }
  _addCheck(t) {
    return new U({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  email(t) {
    return this._addCheck({ kind: "email", ...v.errToObj(t) });
  }
  url(t) {
    return this._addCheck({ kind: "url", ...v.errToObj(t) });
  }
  emoji(t) {
    return this._addCheck({ kind: "emoji", ...v.errToObj(t) });
  }
  uuid(t) {
    return this._addCheck({ kind: "uuid", ...v.errToObj(t) });
  }
  nanoid(t) {
    return this._addCheck({ kind: "nanoid", ...v.errToObj(t) });
  }
  cuid(t) {
    return this._addCheck({ kind: "cuid", ...v.errToObj(t) });
  }
  cuid2(t) {
    return this._addCheck({ kind: "cuid2", ...v.errToObj(t) });
  }
  ulid(t) {
    return this._addCheck({ kind: "ulid", ...v.errToObj(t) });
  }
  base64(t) {
    return this._addCheck({ kind: "base64", ...v.errToObj(t) });
  }
  ip(t) {
    return this._addCheck({ kind: "ip", ...v.errToObj(t) });
  }
  datetime(t) {
    var r, n;
    return typeof t == "string" ? this._addCheck({
      kind: "datetime",
      precision: null,
      offset: !1,
      local: !1,
      message: t
    }) : this._addCheck({
      kind: "datetime",
      precision: typeof (t == null ? void 0 : t.precision) > "u" ? null : t == null ? void 0 : t.precision,
      offset: (r = t == null ? void 0 : t.offset) !== null && r !== void 0 ? r : !1,
      local: (n = t == null ? void 0 : t.local) !== null && n !== void 0 ? n : !1,
      ...v.errToObj(t == null ? void 0 : t.message)
    });
  }
  date(t) {
    return this._addCheck({ kind: "date", message: t });
  }
  time(t) {
    return typeof t == "string" ? this._addCheck({
      kind: "time",
      precision: null,
      message: t
    }) : this._addCheck({
      kind: "time",
      precision: typeof (t == null ? void 0 : t.precision) > "u" ? null : t == null ? void 0 : t.precision,
      ...v.errToObj(t == null ? void 0 : t.message)
    });
  }
  duration(t) {
    return this._addCheck({ kind: "duration", ...v.errToObj(t) });
  }
  regex(t, r) {
    return this._addCheck({
      kind: "regex",
      regex: t,
      ...v.errToObj(r)
    });
  }
  includes(t, r) {
    return this._addCheck({
      kind: "includes",
      value: t,
      position: r == null ? void 0 : r.position,
      ...v.errToObj(r == null ? void 0 : r.message)
    });
  }
  startsWith(t, r) {
    return this._addCheck({
      kind: "startsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  endsWith(t, r) {
    return this._addCheck({
      kind: "endsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t,
      ...v.errToObj(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t,
      ...v.errToObj(r)
    });
  }
  length(t, r) {
    return this._addCheck({
      kind: "length",
      value: t,
      ...v.errToObj(r)
    });
  }
  /**
   * @deprecated Use z.string().min(1) instead.
   * @see {@link ZodString.min}
   */
  nonempty(t) {
    return this.min(1, v.errToObj(t));
  }
  trim() {
    return new U({
      ...this._def,
      checks: [...this._def.checks, { kind: "trim" }]
    });
  }
  toLowerCase() {
    return new U({
      ...this._def,
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    });
  }
  toUpperCase() {
    return new U({
      ...this._def,
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    });
  }
  get isDatetime() {
    return !!this._def.checks.find((t) => t.kind === "datetime");
  }
  get isDate() {
    return !!this._def.checks.find((t) => t.kind === "date");
  }
  get isTime() {
    return !!this._def.checks.find((t) => t.kind === "time");
  }
  get isDuration() {
    return !!this._def.checks.find((t) => t.kind === "duration");
  }
  get isEmail() {
    return !!this._def.checks.find((t) => t.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((t) => t.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((t) => t.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((t) => t.kind === "uuid");
  }
  get isNANOID() {
    return !!this._def.checks.find((t) => t.kind === "nanoid");
  }
  get isCUID() {
    return !!this._def.checks.find((t) => t.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((t) => t.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((t) => t.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((t) => t.kind === "ip");
  }
  get isBase64() {
    return !!this._def.checks.find((t) => t.kind === "base64");
  }
  get minLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
U.create = (e) => {
  var t;
  return new U({
    checks: [],
    typeName: m.ZodString,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ...b(e)
  });
};
function gv(e, t) {
  const r = (e.toString().split(".")[1] || "").length, n = (t.toString().split(".")[1] || "").length, s = r > n ? r : n, i = parseInt(e.toFixed(s).replace(".", "")), o = parseInt(t.toFixed(s).replace(".", ""));
  return i % o / Math.pow(10, s);
}
class X extends T {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte, this.step = this.multipleOf;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = Number(t.data)), this._getType(t) !== h.number) {
      const i = this._getOrReturnCtx(t);
      return p(i, {
        code: d.invalid_type,
        expected: h.number,
        received: i.parsedType
      }), g;
    }
    let n;
    const s = new M();
    for (const i of this._def.checks)
      i.kind === "int" ? $.isInteger(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.invalid_type,
        expected: "integer",
        received: "float",
        message: i.message
      }), s.dirty()) : i.kind === "min" ? (i.inclusive ? t.data < i.value : t.data <= i.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        minimum: i.value,
        type: "number",
        inclusive: i.inclusive,
        exact: !1,
        message: i.message
      }), s.dirty()) : i.kind === "max" ? (i.inclusive ? t.data > i.value : t.data >= i.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        maximum: i.value,
        type: "number",
        inclusive: i.inclusive,
        exact: !1,
        message: i.message
      }), s.dirty()) : i.kind === "multipleOf" ? gv(t.data, i.value) !== 0 && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: i.value,
        message: i.message
      }), s.dirty()) : i.kind === "finite" ? Number.isFinite(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_finite,
        message: i.message
      }), s.dirty()) : $.assertNever(i);
    return { status: s.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, s) {
    return new X({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(s)
        }
      ]
    });
  }
  _addCheck(t) {
    return new X({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  int(t) {
    return this._addCheck({
      kind: "int",
      message: v.toString(t)
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  finite(t) {
    return this._addCheck({
      kind: "finite",
      message: v.toString(t)
    });
  }
  safe(t) {
    return this._addCheck({
      kind: "min",
      inclusive: !0,
      value: Number.MIN_SAFE_INTEGER,
      message: v.toString(t)
    })._addCheck({
      kind: "max",
      inclusive: !0,
      value: Number.MAX_SAFE_INTEGER,
      message: v.toString(t)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
  get isInt() {
    return !!this._def.checks.find((t) => t.kind === "int" || t.kind === "multipleOf" && $.isInteger(t.value));
  }
  get isFinite() {
    let t = null, r = null;
    for (const n of this._def.checks) {
      if (n.kind === "finite" || n.kind === "int" || n.kind === "multipleOf")
        return !0;
      n.kind === "min" ? (r === null || n.value > r) && (r = n.value) : n.kind === "max" && (t === null || n.value < t) && (t = n.value);
    }
    return Number.isFinite(r) && Number.isFinite(t);
  }
}
X.create = (e) => new X({
  checks: [],
  typeName: m.ZodNumber,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ...b(e)
});
class ee extends T {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = BigInt(t.data)), this._getType(t) !== h.bigint) {
      const i = this._getOrReturnCtx(t);
      return p(i, {
        code: d.invalid_type,
        expected: h.bigint,
        received: i.parsedType
      }), g;
    }
    let n;
    const s = new M();
    for (const i of this._def.checks)
      i.kind === "min" ? (i.inclusive ? t.data < i.value : t.data <= i.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        type: "bigint",
        minimum: i.value,
        inclusive: i.inclusive,
        message: i.message
      }), s.dirty()) : i.kind === "max" ? (i.inclusive ? t.data > i.value : t.data >= i.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        type: "bigint",
        maximum: i.value,
        inclusive: i.inclusive,
        message: i.message
      }), s.dirty()) : i.kind === "multipleOf" ? t.data % i.value !== BigInt(0) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: i.value,
        message: i.message
      }), s.dirty()) : $.assertNever(i);
    return { status: s.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, s) {
    return new ee({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(s)
        }
      ]
    });
  }
  _addCheck(t) {
    return new ee({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
ee.create = (e) => {
  var t;
  return new ee({
    checks: [],
    typeName: m.ZodBigInt,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ...b(e)
  });
};
class Be extends T {
  _parse(t) {
    if (this._def.coerce && (t.data = !!t.data), this._getType(t) !== h.boolean) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: h.boolean,
        received: n.parsedType
      }), g;
    }
    return P(t.data);
  }
}
Be.create = (e) => new Be({
  typeName: m.ZodBoolean,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ...b(e)
});
class ue extends T {
  _parse(t) {
    if (this._def.coerce && (t.data = new Date(t.data)), this._getType(t) !== h.date) {
      const i = this._getOrReturnCtx(t);
      return p(i, {
        code: d.invalid_type,
        expected: h.date,
        received: i.parsedType
      }), g;
    }
    if (isNaN(t.data.getTime())) {
      const i = this._getOrReturnCtx(t);
      return p(i, {
        code: d.invalid_date
      }), g;
    }
    const n = new M();
    let s;
    for (const i of this._def.checks)
      i.kind === "min" ? t.data.getTime() < i.value && (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.too_small,
        message: i.message,
        inclusive: !0,
        exact: !1,
        minimum: i.value,
        type: "date"
      }), n.dirty()) : i.kind === "max" ? t.data.getTime() > i.value && (s = this._getOrReturnCtx(t, s), p(s, {
        code: d.too_big,
        message: i.message,
        inclusive: !0,
        exact: !1,
        maximum: i.value,
        type: "date"
      }), n.dirty()) : $.assertNever(i);
    return {
      status: n.value,
      value: new Date(t.data.getTime())
    };
  }
  _addCheck(t) {
    return new ue({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  get minDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
  get maxDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
}
ue.create = (e) => new ue({
  checks: [],
  coerce: (e == null ? void 0 : e.coerce) || !1,
  typeName: m.ZodDate,
  ...b(e)
});
class kt extends T {
  _parse(t) {
    if (this._getType(t) !== h.symbol) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: h.symbol,
        received: n.parsedType
      }), g;
    }
    return P(t.data);
  }
}
kt.create = (e) => new kt({
  typeName: m.ZodSymbol,
  ...b(e)
});
class Ge extends T {
  _parse(t) {
    if (this._getType(t) !== h.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: h.undefined,
        received: n.parsedType
      }), g;
    }
    return P(t.data);
  }
}
Ge.create = (e) => new Ge({
  typeName: m.ZodUndefined,
  ...b(e)
});
class ze extends T {
  _parse(t) {
    if (this._getType(t) !== h.null) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: h.null,
        received: n.parsedType
      }), g;
    }
    return P(t.data);
  }
}
ze.create = (e) => new ze({
  typeName: m.ZodNull,
  ...b(e)
});
class _e extends T {
  constructor() {
    super(...arguments), this._any = !0;
  }
  _parse(t) {
    return P(t.data);
  }
}
_e.create = (e) => new _e({
  typeName: m.ZodAny,
  ...b(e)
});
class ce extends T {
  constructor() {
    super(...arguments), this._unknown = !0;
  }
  _parse(t) {
    return P(t.data);
  }
}
ce.create = (e) => new ce({
  typeName: m.ZodUnknown,
  ...b(e)
});
class W extends T {
  _parse(t) {
    const r = this._getOrReturnCtx(t);
    return p(r, {
      code: d.invalid_type,
      expected: h.never,
      received: r.parsedType
    }), g;
  }
}
W.create = (e) => new W({
  typeName: m.ZodNever,
  ...b(e)
});
class jt extends T {
  _parse(t) {
    if (this._getType(t) !== h.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: h.void,
        received: n.parsedType
      }), g;
    }
    return P(t.data);
  }
}
jt.create = (e) => new jt({
  typeName: m.ZodVoid,
  ...b(e)
});
class Z extends T {
  _parse(t) {
    const { ctx: r, status: n } = this._processInputParams(t), s = this._def;
    if (r.parsedType !== h.array)
      return p(r, {
        code: d.invalid_type,
        expected: h.array,
        received: r.parsedType
      }), g;
    if (s.exactLength !== null) {
      const o = r.data.length > s.exactLength.value, c = r.data.length < s.exactLength.value;
      (o || c) && (p(r, {
        code: o ? d.too_big : d.too_small,
        minimum: c ? s.exactLength.value : void 0,
        maximum: o ? s.exactLength.value : void 0,
        type: "array",
        inclusive: !0,
        exact: !0,
        message: s.exactLength.message
      }), n.dirty());
    }
    if (s.minLength !== null && r.data.length < s.minLength.value && (p(r, {
      code: d.too_small,
      minimum: s.minLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: s.minLength.message
    }), n.dirty()), s.maxLength !== null && r.data.length > s.maxLength.value && (p(r, {
      code: d.too_big,
      maximum: s.maxLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: s.maxLength.message
    }), n.dirty()), r.common.async)
      return Promise.all([...r.data].map((o, c) => s.type._parseAsync(new B(r, o, r.path, c)))).then((o) => M.mergeArray(n, o));
    const i = [...r.data].map((o, c) => s.type._parseSync(new B(r, o, r.path, c)));
    return M.mergeArray(n, i);
  }
  get element() {
    return this._def.type;
  }
  min(t, r) {
    return new Z({
      ...this._def,
      minLength: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new Z({
      ...this._def,
      maxLength: { value: t, message: v.toString(r) }
    });
  }
  length(t, r) {
    return new Z({
      ...this._def,
      exactLength: { value: t, message: v.toString(r) }
    });
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
Z.create = (e, t) => new Z({
  type: e,
  minLength: null,
  maxLength: null,
  exactLength: null,
  typeName: m.ZodArray,
  ...b(t)
});
function ve(e) {
  if (e instanceof x) {
    const t = {};
    for (const r in e.shape) {
      const n = e.shape[r];
      t[r] = q.create(ve(n));
    }
    return new x({
      ...e._def,
      shape: () => t
    });
  } else return e instanceof Z ? new Z({
    ...e._def,
    type: ve(e.element)
  }) : e instanceof q ? q.create(ve(e.unwrap())) : e instanceof re ? re.create(ve(e.unwrap())) : e instanceof G ? G.create(e.items.map((t) => ve(t))) : e;
}
class x extends T {
  constructor() {
    super(...arguments), this._cached = null, this.nonstrict = this.passthrough, this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const t = this._def.shape(), r = $.objectKeys(t);
    return this._cached = { shape: t, keys: r };
  }
  _parse(t) {
    if (this._getType(t) !== h.object) {
      const l = this._getOrReturnCtx(t);
      return p(l, {
        code: d.invalid_type,
        expected: h.object,
        received: l.parsedType
      }), g;
    }
    const { status: n, ctx: s } = this._processInputParams(t), { shape: i, keys: o } = this._getCached(), c = [];
    if (!(this._def.catchall instanceof W && this._def.unknownKeys === "strip"))
      for (const l in s.data)
        o.includes(l) || c.push(l);
    const u = [];
    for (const l of o) {
      const f = i[l], _ = s.data[l];
      u.push({
        key: { status: "valid", value: l },
        value: f._parse(new B(s, _, s.path, l)),
        alwaysSet: l in s.data
      });
    }
    if (this._def.catchall instanceof W) {
      const l = this._def.unknownKeys;
      if (l === "passthrough")
        for (const f of c)
          u.push({
            key: { status: "valid", value: f },
            value: { status: "valid", value: s.data[f] }
          });
      else if (l === "strict")
        c.length > 0 && (p(s, {
          code: d.unrecognized_keys,
          keys: c
        }), n.dirty());
      else if (l !== "strip") throw new Error("Internal ZodObject error: invalid unknownKeys value.");
    } else {
      const l = this._def.catchall;
      for (const f of c) {
        const _ = s.data[f];
        u.push({
          key: { status: "valid", value: f },
          value: l._parse(
            new B(s, _, s.path, f)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: f in s.data
        });
      }
    }
    return s.common.async ? Promise.resolve().then(async () => {
      const l = [];
      for (const f of u) {
        const _ = await f.key, A = await f.value;
        l.push({
          key: _,
          value: A,
          alwaysSet: f.alwaysSet
        });
      }
      return l;
    }).then((l) => M.mergeObjectSync(n, l)) : M.mergeObjectSync(n, u);
  }
  get shape() {
    return this._def.shape();
  }
  strict(t) {
    return v.errToObj, new x({
      ...this._def,
      unknownKeys: "strict",
      ...t !== void 0 ? {
        errorMap: (r, n) => {
          var s, i, o, c;
          const u = (o = (i = (s = this._def).errorMap) === null || i === void 0 ? void 0 : i.call(s, r, n).message) !== null && o !== void 0 ? o : n.defaultError;
          return r.code === "unrecognized_keys" ? {
            message: (c = v.errToObj(t).message) !== null && c !== void 0 ? c : u
          } : {
            message: u
          };
        }
      } : {}
    });
  }
  strip() {
    return new x({
      ...this._def,
      unknownKeys: "strip"
    });
  }
  passthrough() {
    return new x({
      ...this._def,
      unknownKeys: "passthrough"
    });
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(t) {
    return new x({
      ...this._def,
      shape: () => ({
        ...this._def.shape(),
        ...t
      })
    });
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(t) {
    return new x({
      unknownKeys: t._def.unknownKeys,
      catchall: t._def.catchall,
      shape: () => ({
        ...this._def.shape(),
        ...t._def.shape()
      }),
      typeName: m.ZodObject
    });
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(t, r) {
    return this.augment({ [t]: r });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(t) {
    return new x({
      ...this._def,
      catchall: t
    });
  }
  pick(t) {
    const r = {};
    return $.objectKeys(t).forEach((n) => {
      t[n] && this.shape[n] && (r[n] = this.shape[n]);
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  omit(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      t[n] || (r[n] = this.shape[n]);
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return ve(this);
  }
  partial(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      const s = this.shape[n];
      t && !t[n] ? r[n] = s : r[n] = s.optional();
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  required(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      if (t && !t[n])
        r[n] = this.shape[n];
      else {
        let i = this.shape[n];
        for (; i instanceof q; )
          i = i._def.innerType;
        r[n] = i;
      }
    }), new x({
      ...this._def,
      shape: () => r
    });
  }
  keyof() {
    return Ma($.objectKeys(this.shape));
  }
}
x.create = (e, t) => new x({
  shape: () => e,
  unknownKeys: "strip",
  catchall: W.create(),
  typeName: m.ZodObject,
  ...b(t)
});
x.strictCreate = (e, t) => new x({
  shape: () => e,
  unknownKeys: "strict",
  catchall: W.create(),
  typeName: m.ZodObject,
  ...b(t)
});
x.lazycreate = (e, t) => new x({
  shape: e,
  unknownKeys: "strip",
  catchall: W.create(),
  typeName: m.ZodObject,
  ...b(t)
});
class Fe extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = this._def.options;
    function s(i) {
      for (const c of i)
        if (c.result.status === "valid")
          return c.result;
      for (const c of i)
        if (c.result.status === "dirty")
          return r.common.issues.push(...c.ctx.common.issues), c.result;
      const o = i.map((c) => new L(c.ctx.common.issues));
      return p(r, {
        code: d.invalid_union,
        unionErrors: o
      }), g;
    }
    if (r.common.async)
      return Promise.all(n.map(async (i) => {
        const o = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        };
        return {
          result: await i._parseAsync({
            data: r.data,
            path: r.path,
            parent: o
          }),
          ctx: o
        };
      })).then(s);
    {
      let i;
      const o = [];
      for (const u of n) {
        const l = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        }, f = u._parseSync({
          data: r.data,
          path: r.path,
          parent: l
        });
        if (f.status === "valid")
          return f;
        f.status === "dirty" && !i && (i = { result: f, ctx: l }), l.common.issues.length && o.push(l.common.issues);
      }
      if (i)
        return r.common.issues.push(...i.ctx.common.issues), i.result;
      const c = o.map((u) => new L(u));
      return p(r, {
        code: d.invalid_union,
        unionErrors: c
      }), g;
    }
  }
  get options() {
    return this._def.options;
  }
}
Fe.create = (e, t) => new Fe({
  options: e,
  typeName: m.ZodUnion,
  ...b(t)
});
const K = (e) => e instanceof Qe ? K(e.schema) : e instanceof H ? K(e.innerType()) : e instanceof Je ? [e.value] : e instanceof te ? e.options : e instanceof Ye ? $.objectValues(e.enum) : e instanceof Xe ? K(e._def.innerType) : e instanceof Ge ? [void 0] : e instanceof ze ? [null] : e instanceof q ? [void 0, ...K(e.unwrap())] : e instanceof re ? [null, ...K(e.unwrap())] : e instanceof Vr || e instanceof tt ? K(e.unwrap()) : e instanceof et ? K(e._def.innerType) : [];
class zt extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== h.object)
      return p(r, {
        code: d.invalid_type,
        expected: h.object,
        received: r.parsedType
      }), g;
    const n = this.discriminator, s = r.data[n], i = this.optionsMap.get(s);
    return i ? r.common.async ? i._parseAsync({
      data: r.data,
      path: r.path,
      parent: r
    }) : i._parseSync({
      data: r.data,
      path: r.path,
      parent: r
    }) : (p(r, {
      code: d.invalid_union_discriminator,
      options: Array.from(this.optionsMap.keys()),
      path: [n]
    }), g);
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(t, r, n) {
    const s = /* @__PURE__ */ new Map();
    for (const i of r) {
      const o = K(i.shape[t]);
      if (!o.length)
        throw new Error(`A discriminator value for key \`${t}\` could not be extracted from all schema options`);
      for (const c of o) {
        if (s.has(c))
          throw new Error(`Discriminator property ${String(t)} has duplicate value ${String(c)}`);
        s.set(c, i);
      }
    }
    return new zt({
      typeName: m.ZodDiscriminatedUnion,
      discriminator: t,
      options: r,
      optionsMap: s,
      ...b(n)
    });
  }
}
function Tr(e, t) {
  const r = J(e), n = J(t);
  if (e === t)
    return { valid: !0, data: e };
  if (r === h.object && n === h.object) {
    const s = $.objectKeys(t), i = $.objectKeys(e).filter((c) => s.indexOf(c) !== -1), o = { ...e, ...t };
    for (const c of i) {
      const u = Tr(e[c], t[c]);
      if (!u.valid)
        return { valid: !1 };
      o[c] = u.data;
    }
    return { valid: !0, data: o };
  } else if (r === h.array && n === h.array) {
    if (e.length !== t.length)
      return { valid: !1 };
    const s = [];
    for (let i = 0; i < e.length; i++) {
      const o = e[i], c = t[i], u = Tr(o, c);
      if (!u.valid)
        return { valid: !1 };
      s.push(u.data);
    }
    return { valid: !0, data: s };
  } else return r === h.date && n === h.date && +e == +t ? { valid: !0, data: e } : { valid: !1 };
}
class Ke extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), s = (i, o) => {
      if (_r(i) || _r(o))
        return g;
      const c = Tr(i.value, o.value);
      return c.valid ? ((br(i) || br(o)) && r.dirty(), { status: r.value, value: c.data }) : (p(n, {
        code: d.invalid_intersection_types
      }), g);
    };
    return n.common.async ? Promise.all([
      this._def.left._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      }),
      this._def.right._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      })
    ]).then(([i, o]) => s(i, o)) : s(this._def.left._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }), this._def.right._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }));
  }
}
Ke.create = (e, t, r) => new Ke({
  left: e,
  right: t,
  typeName: m.ZodIntersection,
  ...b(r)
});
class G extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== h.array)
      return p(n, {
        code: d.invalid_type,
        expected: h.array,
        received: n.parsedType
      }), g;
    if (n.data.length < this._def.items.length)
      return p(n, {
        code: d.too_small,
        minimum: this._def.items.length,
        inclusive: !0,
        exact: !1,
        type: "array"
      }), g;
    !this._def.rest && n.data.length > this._def.items.length && (p(n, {
      code: d.too_big,
      maximum: this._def.items.length,
      inclusive: !0,
      exact: !1,
      type: "array"
    }), r.dirty());
    const i = [...n.data].map((o, c) => {
      const u = this._def.items[c] || this._def.rest;
      return u ? u._parse(new B(n, o, n.path, c)) : null;
    }).filter((o) => !!o);
    return n.common.async ? Promise.all(i).then((o) => M.mergeArray(r, o)) : M.mergeArray(r, i);
  }
  get items() {
    return this._def.items;
  }
  rest(t) {
    return new G({
      ...this._def,
      rest: t
    });
  }
}
G.create = (e, t) => {
  if (!Array.isArray(e))
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  return new G({
    items: e,
    typeName: m.ZodTuple,
    rest: null,
    ...b(t)
  });
};
class We extends T {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== h.object)
      return p(n, {
        code: d.invalid_type,
        expected: h.object,
        received: n.parsedType
      }), g;
    const s = [], i = this._def.keyType, o = this._def.valueType;
    for (const c in n.data)
      s.push({
        key: i._parse(new B(n, c, n.path, c)),
        value: o._parse(new B(n, n.data[c], n.path, c)),
        alwaysSet: c in n.data
      });
    return n.common.async ? M.mergeObjectAsync(r, s) : M.mergeObjectSync(r, s);
  }
  get element() {
    return this._def.valueType;
  }
  static create(t, r, n) {
    return r instanceof T ? new We({
      keyType: t,
      valueType: r,
      typeName: m.ZodRecord,
      ...b(n)
    }) : new We({
      keyType: U.create(),
      valueType: t,
      typeName: m.ZodRecord,
      ...b(r)
    });
  }
}
class Rt extends T {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== h.map)
      return p(n, {
        code: d.invalid_type,
        expected: h.map,
        received: n.parsedType
      }), g;
    const s = this._def.keyType, i = this._def.valueType, o = [...n.data.entries()].map(([c, u], l) => ({
      key: s._parse(new B(n, c, n.path, [l, "key"])),
      value: i._parse(new B(n, u, n.path, [l, "value"]))
    }));
    if (n.common.async) {
      const c = /* @__PURE__ */ new Map();
      return Promise.resolve().then(async () => {
        for (const u of o) {
          const l = await u.key, f = await u.value;
          if (l.status === "aborted" || f.status === "aborted")
            return g;
          (l.status === "dirty" || f.status === "dirty") && r.dirty(), c.set(l.value, f.value);
        }
        return { status: r.value, value: c };
      });
    } else {
      const c = /* @__PURE__ */ new Map();
      for (const u of o) {
        const l = u.key, f = u.value;
        if (l.status === "aborted" || f.status === "aborted")
          return g;
        (l.status === "dirty" || f.status === "dirty") && r.dirty(), c.set(l.value, f.value);
      }
      return { status: r.value, value: c };
    }
  }
}
Rt.create = (e, t, r) => new Rt({
  valueType: t,
  keyType: e,
  typeName: m.ZodMap,
  ...b(r)
});
class le extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== h.set)
      return p(n, {
        code: d.invalid_type,
        expected: h.set,
        received: n.parsedType
      }), g;
    const s = this._def;
    s.minSize !== null && n.data.size < s.minSize.value && (p(n, {
      code: d.too_small,
      minimum: s.minSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: s.minSize.message
    }), r.dirty()), s.maxSize !== null && n.data.size > s.maxSize.value && (p(n, {
      code: d.too_big,
      maximum: s.maxSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: s.maxSize.message
    }), r.dirty());
    const i = this._def.valueType;
    function o(u) {
      const l = /* @__PURE__ */ new Set();
      for (const f of u) {
        if (f.status === "aborted")
          return g;
        f.status === "dirty" && r.dirty(), l.add(f.value);
      }
      return { status: r.value, value: l };
    }
    const c = [...n.data.values()].map((u, l) => i._parse(new B(n, u, n.path, l)));
    return n.common.async ? Promise.all(c).then((u) => o(u)) : o(c);
  }
  min(t, r) {
    return new le({
      ...this._def,
      minSize: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new le({
      ...this._def,
      maxSize: { value: t, message: v.toString(r) }
    });
  }
  size(t, r) {
    return this.min(t, r).max(t, r);
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
le.create = (e, t) => new le({
  valueType: e,
  minSize: null,
  maxSize: null,
  typeName: m.ZodSet,
  ...b(t)
});
class ge extends T {
  constructor() {
    super(...arguments), this.validate = this.implement;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== h.function)
      return p(r, {
        code: d.invalid_type,
        expected: h.function,
        received: r.parsedType
      }), g;
    function n(c, u) {
      return wt({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          Et(),
          ye
        ].filter((l) => !!l),
        issueData: {
          code: d.invalid_arguments,
          argumentsError: u
        }
      });
    }
    function s(c, u) {
      return wt({
        data: c,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          Et(),
          ye
        ].filter((l) => !!l),
        issueData: {
          code: d.invalid_return_type,
          returnTypeError: u
        }
      });
    }
    const i = { errorMap: r.common.contextualErrorMap }, o = r.data;
    if (this._def.returns instanceof be) {
      const c = this;
      return P(async function(...u) {
        const l = new L([]), f = await c._def.args.parseAsync(u, i).catch((I) => {
          throw l.addIssue(n(u, I)), l;
        }), _ = await Reflect.apply(o, this, f);
        return await c._def.returns._def.type.parseAsync(_, i).catch((I) => {
          throw l.addIssue(s(_, I)), l;
        });
      });
    } else {
      const c = this;
      return P(function(...u) {
        const l = c._def.args.safeParse(u, i);
        if (!l.success)
          throw new L([n(u, l.error)]);
        const f = Reflect.apply(o, this, l.data), _ = c._def.returns.safeParse(f, i);
        if (!_.success)
          throw new L([s(f, _.error)]);
        return _.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...t) {
    return new ge({
      ...this._def,
      args: G.create(t).rest(ce.create())
    });
  }
  returns(t) {
    return new ge({
      ...this._def,
      returns: t
    });
  }
  implement(t) {
    return this.parse(t);
  }
  strictImplement(t) {
    return this.parse(t);
  }
  static create(t, r, n) {
    return new ge({
      args: t || G.create([]).rest(ce.create()),
      returns: r || ce.create(),
      typeName: m.ZodFunction,
      ...b(n)
    });
  }
}
class Qe extends T {
  get schema() {
    return this._def.getter();
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    return this._def.getter()._parse({ data: r.data, path: r.path, parent: r });
  }
}
Qe.create = (e, t) => new Qe({
  getter: e,
  typeName: m.ZodLazy,
  ...b(t)
});
class Je extends T {
  _parse(t) {
    if (t.data !== this._def.value) {
      const r = this._getOrReturnCtx(t);
      return p(r, {
        received: r.data,
        code: d.invalid_literal,
        expected: this._def.value
      }), g;
    }
    return { status: "valid", value: t.data };
  }
  get value() {
    return this._def.value;
  }
}
Je.create = (e, t) => new Je({
  value: e,
  typeName: m.ZodLiteral,
  ...b(t)
});
function Ma(e, t) {
  return new te({
    values: e,
    typeName: m.ZodEnum,
    ...b(t)
  });
}
class te extends T {
  constructor() {
    super(...arguments), Ue.set(this, void 0);
  }
  _parse(t) {
    if (typeof t.data != "string") {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        expected: $.joinValues(n),
        received: r.parsedType,
        code: d.invalid_type
      }), g;
    }
    if (xt(this, Ue) || Ra(this, Ue, new Set(this._def.values)), !xt(this, Ue).has(t.data)) {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        received: r.data,
        code: d.invalid_enum_value,
        options: n
      }), g;
    }
    return P(t.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Values() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  extract(t, r = this._def) {
    return te.create(t, {
      ...this._def,
      ...r
    });
  }
  exclude(t, r = this._def) {
    return te.create(this.options.filter((n) => !t.includes(n)), {
      ...this._def,
      ...r
    });
  }
}
Ue = /* @__PURE__ */ new WeakMap();
te.create = Ma;
class Ye extends T {
  constructor() {
    super(...arguments), Ze.set(this, void 0);
  }
  _parse(t) {
    const r = $.getValidEnumValues(this._def.values), n = this._getOrReturnCtx(t);
    if (n.parsedType !== h.string && n.parsedType !== h.number) {
      const s = $.objectValues(r);
      return p(n, {
        expected: $.joinValues(s),
        received: n.parsedType,
        code: d.invalid_type
      }), g;
    }
    if (xt(this, Ze) || Ra(this, Ze, new Set($.getValidEnumValues(this._def.values))), !xt(this, Ze).has(t.data)) {
      const s = $.objectValues(r);
      return p(n, {
        received: n.data,
        code: d.invalid_enum_value,
        options: s
      }), g;
    }
    return P(t.data);
  }
  get enum() {
    return this._def.values;
  }
}
Ze = /* @__PURE__ */ new WeakMap();
Ye.create = (e, t) => new Ye({
  values: e,
  typeName: m.ZodNativeEnum,
  ...b(t)
});
class be extends T {
  unwrap() {
    return this._def.type;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== h.promise && r.common.async === !1)
      return p(r, {
        code: d.invalid_type,
        expected: h.promise,
        received: r.parsedType
      }), g;
    const n = r.parsedType === h.promise ? r.data : Promise.resolve(r.data);
    return P(n.then((s) => this._def.type.parseAsync(s, {
      path: r.path,
      errorMap: r.common.contextualErrorMap
    })));
  }
}
be.create = (e, t) => new be({
  type: e,
  typeName: m.ZodPromise,
  ...b(t)
});
class H extends T {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === m.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), s = this._def.effect || null, i = {
      addIssue: (o) => {
        p(n, o), o.fatal ? r.abort() : r.dirty();
      },
      get path() {
        return n.path;
      }
    };
    if (i.addIssue = i.addIssue.bind(i), s.type === "preprocess") {
      const o = s.transform(n.data, i);
      if (n.common.async)
        return Promise.resolve(o).then(async (c) => {
          if (r.value === "aborted")
            return g;
          const u = await this._def.schema._parseAsync({
            data: c,
            path: n.path,
            parent: n
          });
          return u.status === "aborted" ? g : u.status === "dirty" || r.value === "dirty" ? me(u.value) : u;
        });
      {
        if (r.value === "aborted")
          return g;
        const c = this._def.schema._parseSync({
          data: o,
          path: n.path,
          parent: n
        });
        return c.status === "aborted" ? g : c.status === "dirty" || r.value === "dirty" ? me(c.value) : c;
      }
    }
    if (s.type === "refinement") {
      const o = (c) => {
        const u = s.refinement(c, i);
        if (n.common.async)
          return Promise.resolve(u);
        if (u instanceof Promise)
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        return c;
      };
      if (n.common.async === !1) {
        const c = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return c.status === "aborted" ? g : (c.status === "dirty" && r.dirty(), o(c.value), { status: r.value, value: c.value });
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((c) => c.status === "aborted" ? g : (c.status === "dirty" && r.dirty(), o(c.value).then(() => ({ status: r.value, value: c.value }))));
    }
    if (s.type === "transform")
      if (n.common.async === !1) {
        const o = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        if (!He(o))
          return o;
        const c = s.transform(o.value, i);
        if (c instanceof Promise)
          throw new Error("Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.");
        return { status: r.value, value: c };
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((o) => He(o) ? Promise.resolve(s.transform(o.value, i)).then((c) => ({ status: r.value, value: c })) : o);
    $.assertNever(s);
  }
}
H.create = (e, t, r) => new H({
  schema: e,
  typeName: m.ZodEffects,
  effect: t,
  ...b(r)
});
H.createWithPreprocess = (e, t, r) => new H({
  schema: t,
  effect: { type: "preprocess", transform: e },
  typeName: m.ZodEffects,
  ...b(r)
});
class q extends T {
  _parse(t) {
    return this._getType(t) === h.undefined ? P(void 0) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
q.create = (e, t) => new q({
  innerType: e,
  typeName: m.ZodOptional,
  ...b(t)
});
class re extends T {
  _parse(t) {
    return this._getType(t) === h.null ? P(null) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
re.create = (e, t) => new re({
  innerType: e,
  typeName: m.ZodNullable,
  ...b(t)
});
class Xe extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    let n = r.data;
    return r.parsedType === h.undefined && (n = this._def.defaultValue()), this._def.innerType._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
}
Xe.create = (e, t) => new Xe({
  innerType: e,
  typeName: m.ZodDefault,
  defaultValue: typeof t.default == "function" ? t.default : () => t.default,
  ...b(t)
});
class et extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = {
      ...r,
      common: {
        ...r.common,
        issues: []
      }
    }, s = this._def.innerType._parse({
      data: n.data,
      path: n.path,
      parent: {
        ...n
      }
    });
    return qe(s) ? s.then((i) => ({
      status: "valid",
      value: i.status === "valid" ? i.value : this._def.catchValue({
        get error() {
          return new L(n.common.issues);
        },
        input: n.data
      })
    })) : {
      status: "valid",
      value: s.status === "valid" ? s.value : this._def.catchValue({
        get error() {
          return new L(n.common.issues);
        },
        input: n.data
      })
    };
  }
  removeCatch() {
    return this._def.innerType;
  }
}
et.create = (e, t) => new et({
  innerType: e,
  typeName: m.ZodCatch,
  catchValue: typeof t.catch == "function" ? t.catch : () => t.catch,
  ...b(t)
});
class Ot extends T {
  _parse(t) {
    if (this._getType(t) !== h.nan) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: h.nan,
        received: n.parsedType
      }), g;
    }
    return { status: "valid", value: t.data };
  }
}
Ot.create = (e) => new Ot({
  typeName: m.ZodNaN,
  ...b(e)
});
const yv = Symbol("zod_brand");
class Vr extends T {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = r.data;
    return this._def.type._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  unwrap() {
    return this._def.type;
  }
}
class at extends T {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.common.async)
      return (async () => {
        const i = await this._def.in._parseAsync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return i.status === "aborted" ? g : i.status === "dirty" ? (r.dirty(), me(i.value)) : this._def.out._parseAsync({
          data: i.value,
          path: n.path,
          parent: n
        });
      })();
    {
      const s = this._def.in._parseSync({
        data: n.data,
        path: n.path,
        parent: n
      });
      return s.status === "aborted" ? g : s.status === "dirty" ? (r.dirty(), {
        status: "dirty",
        value: s.value
      }) : this._def.out._parseSync({
        data: s.value,
        path: n.path,
        parent: n
      });
    }
  }
  static create(t, r) {
    return new at({
      in: t,
      out: r,
      typeName: m.ZodPipeline
    });
  }
}
class tt extends T {
  _parse(t) {
    const r = this._def.innerType._parse(t), n = (s) => (He(s) && (s.value = Object.freeze(s.value)), s);
    return qe(r) ? r.then((s) => n(s)) : n(r);
  }
  unwrap() {
    return this._def.innerType;
  }
}
tt.create = (e, t) => new tt({
  innerType: e,
  typeName: m.ZodReadonly,
  ...b(t)
});
function Na(e, t = {}, r) {
  return e ? _e.create().superRefine((n, s) => {
    var i, o;
    if (!e(n)) {
      const c = typeof t == "function" ? t(n) : typeof t == "string" ? { message: t } : t, u = (o = (i = c.fatal) !== null && i !== void 0 ? i : r) !== null && o !== void 0 ? o : !0, l = typeof c == "string" ? { message: c } : c;
      s.addIssue({ code: "custom", ...l, fatal: u });
    }
  }) : _e.create();
}
const _v = {
  object: x.lazycreate
};
var m;
(function(e) {
  e.ZodString = "ZodString", e.ZodNumber = "ZodNumber", e.ZodNaN = "ZodNaN", e.ZodBigInt = "ZodBigInt", e.ZodBoolean = "ZodBoolean", e.ZodDate = "ZodDate", e.ZodSymbol = "ZodSymbol", e.ZodUndefined = "ZodUndefined", e.ZodNull = "ZodNull", e.ZodAny = "ZodAny", e.ZodUnknown = "ZodUnknown", e.ZodNever = "ZodNever", e.ZodVoid = "ZodVoid", e.ZodArray = "ZodArray", e.ZodObject = "ZodObject", e.ZodUnion = "ZodUnion", e.ZodDiscriminatedUnion = "ZodDiscriminatedUnion", e.ZodIntersection = "ZodIntersection", e.ZodTuple = "ZodTuple", e.ZodRecord = "ZodRecord", e.ZodMap = "ZodMap", e.ZodSet = "ZodSet", e.ZodFunction = "ZodFunction", e.ZodLazy = "ZodLazy", e.ZodLiteral = "ZodLiteral", e.ZodEnum = "ZodEnum", e.ZodEffects = "ZodEffects", e.ZodNativeEnum = "ZodNativeEnum", e.ZodOptional = "ZodOptional", e.ZodNullable = "ZodNullable", e.ZodDefault = "ZodDefault", e.ZodCatch = "ZodCatch", e.ZodPromise = "ZodPromise", e.ZodBranded = "ZodBranded", e.ZodPipeline = "ZodPipeline", e.ZodReadonly = "ZodReadonly";
})(m || (m = {}));
const bv = (e, t = {
  message: `Input not instance of ${e.name}`
}) => Na((r) => r instanceof e, t), Pa = U.create, Va = X.create, Tv = Ot.create, $v = ee.create, Da = Be.create, Av = ue.create, Sv = kt.create, Ev = Ge.create, wv = ze.create, xv = _e.create, kv = ce.create, jv = W.create, Rv = jt.create, Ov = Z.create, Cv = x.create, Iv = x.strictCreate, Mv = Fe.create, Nv = zt.create, Pv = Ke.create, Vv = G.create, Dv = We.create, Lv = Rt.create, Uv = le.create, Zv = ge.create, Hv = Qe.create, qv = Je.create, Bv = te.create, Gv = Ye.create, zv = be.create, Rn = H.create, Fv = q.create, Kv = re.create, Wv = H.createWithPreprocess, Qv = at.create, Jv = () => Pa().optional(), Yv = () => Va().optional(), Xv = () => Da().optional(), em = {
  string: (e) => U.create({ ...e, coerce: !0 }),
  number: (e) => X.create({ ...e, coerce: !0 }),
  boolean: (e) => Be.create({
    ...e,
    coerce: !0
  }),
  bigint: (e) => ee.create({ ...e, coerce: !0 }),
  date: (e) => ue.create({ ...e, coerce: !0 })
}, tm = g;
var a = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: ye,
  setErrorMap: tv,
  getErrorMap: Et,
  makeIssue: wt,
  EMPTY_PATH: rv,
  addIssueToContext: p,
  ParseStatus: M,
  INVALID: g,
  DIRTY: me,
  OK: P,
  isAborted: _r,
  isDirty: br,
  isValid: He,
  isAsync: qe,
  get util() {
    return $;
  },
  get objectUtil() {
    return yr;
  },
  ZodParsedType: h,
  getParsedType: J,
  ZodType: T,
  datetimeRegex: Ia,
  ZodString: U,
  ZodNumber: X,
  ZodBigInt: ee,
  ZodBoolean: Be,
  ZodDate: ue,
  ZodSymbol: kt,
  ZodUndefined: Ge,
  ZodNull: ze,
  ZodAny: _e,
  ZodUnknown: ce,
  ZodNever: W,
  ZodVoid: jt,
  ZodArray: Z,
  ZodObject: x,
  ZodUnion: Fe,
  ZodDiscriminatedUnion: zt,
  ZodIntersection: Ke,
  ZodTuple: G,
  ZodRecord: We,
  ZodMap: Rt,
  ZodSet: le,
  ZodFunction: ge,
  ZodLazy: Qe,
  ZodLiteral: Je,
  ZodEnum: te,
  ZodNativeEnum: Ye,
  ZodPromise: be,
  ZodEffects: H,
  ZodTransformer: H,
  ZodOptional: q,
  ZodNullable: re,
  ZodDefault: Xe,
  ZodCatch: et,
  ZodNaN: Ot,
  BRAND: yv,
  ZodBranded: Vr,
  ZodPipeline: at,
  ZodReadonly: tt,
  custom: Na,
  Schema: T,
  ZodSchema: T,
  late: _v,
  get ZodFirstPartyTypeKind() {
    return m;
  },
  coerce: em,
  any: xv,
  array: Ov,
  bigint: $v,
  boolean: Da,
  date: Av,
  discriminatedUnion: Nv,
  effect: Rn,
  enum: Bv,
  function: Zv,
  instanceof: bv,
  intersection: Pv,
  lazy: Hv,
  literal: qv,
  map: Lv,
  nan: Tv,
  nativeEnum: Gv,
  never: jv,
  null: wv,
  nullable: Kv,
  number: Va,
  object: Cv,
  oboolean: Xv,
  onumber: Yv,
  optional: Fv,
  ostring: Jv,
  pipeline: Qv,
  preprocess: Wv,
  promise: zv,
  record: Dv,
  set: Uv,
  strictObject: Iv,
  string: Pa,
  symbol: Sv,
  transformer: Rn,
  tuple: Vv,
  undefined: Ev,
  union: Mv,
  unknown: kv,
  void: Rv,
  NEVER: tm,
  ZodIssueCode: d,
  quotelessJson: ev,
  ZodError: L
}), S = (e) => e, rm = class {
  /**
   * @package
   */
  constructor(e, t, r) {
    this.versionMap = e, this.latestVersion = t, this.getVersion = r;
  }
  /**
   * Returns whether the given data is a valid entity of any version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of any version of the entity.
   */
  is(e) {
    let t = this.getVersion(e);
    if (t === null)
      return !1;
    const r = this.versionMap[t];
    return r ? r.schema.safeParse(e).success : !1;
  }
  /**
   * Returns whether the given data is a valid entity of the latest version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of the latest version of the entity.
   */
  isLatest(e) {
    return this.versionMap[this.latestVersion].schema.safeParse(e).success;
  }
  /**
   * Similar to Zod's `safeParse` method, but also migrates the data to the latest version.
   * @param data The data to parse
   * @returns The result from parsing data, if successful, older versions are migrated to the latest version
   */
  safeParse(e) {
    const t = this.getVersion(e);
    if (t === null)
      return { type: "err", error: { type: "VER_CHECK_FAIL" } };
    const r = this.versionMap[t];
    if (!r)
      return { type: "err", error: { type: "INVALID_VER" } };
    const n = r.schema.safeParse(e);
    if (!n.success)
      return {
        type: "err",
        error: {
          type: "GIVEN_VER_VALIDATION_FAIL",
          version: t,
          versionDef: r,
          error: n.error
        }
      };
    let s = n.data;
    for (let i = t + 1; i <= this.latestVersion; i++) {
      const o = this.versionMap[i];
      if (!o)
        return {
          type: "err",
          error: { type: "BUG_NO_INTERMEDIATE_FOUND", missingVer: i }
        };
      if (o.initial)
        return {
          type: "err",
          error: { type: "BUG_INTERMEDIATE_MARKED_INITIAL", ver: i }
        };
      s = o.up(s);
    }
    return { type: "ok", value: s };
  }
};
function st(e) {
  return new rm(e.versionMap, e.latestVersion, e.getVersion);
}
function Ct(e) {
  return a.custom((t) => e.is(t)).transform((t) => {
    const r = e.safeParse(t);
    if (r.type !== "ok")
      throw new Error(
        "Invalid entity definition. `entity.is` returned success, safeParse failed."
      );
    return r.value;
  });
}
var nm = "__lodash_hash_undefined__";
function am(e) {
  return this.__data__.set(e, nm), this;
}
var sm = am;
function im(e) {
  return this.__data__.has(e);
}
var om = im, cm = ca, um = sm, lm = om;
function It(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.__data__ = new cm(); ++t < r; )
    this.add(e[t]);
}
It.prototype.add = It.prototype.push = um;
It.prototype.has = lm;
var dm = It;
function fm(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n; )
    if (t(e[r], r, e))
      return !0;
  return !1;
}
var pm = fm;
function hm(e, t) {
  return e.has(t);
}
var vm = hm, mm = dm, gm = pm, ym = vm, _m = 1, bm = 2;
function Tm(e, t, r, n, s, i) {
  var o = r & _m, c = e.length, u = t.length;
  if (c != u && !(o && u > c))
    return !1;
  var l = i.get(e), f = i.get(t);
  if (l && f)
    return l == t && f == e;
  var _ = -1, A = !0, I = r & bm ? new mm() : void 0;
  for (i.set(e, t), i.set(t, e); ++_ < c; ) {
    var V = e[_], D = t[_];
    if (n)
      var O = o ? n(D, V, _, t, e, i) : n(V, D, _, e, t, i);
    if (O !== void 0) {
      if (O)
        continue;
      A = !1;
      break;
    }
    if (I) {
      if (!gm(t, function(N, ie) {
        if (!ym(I, ie) && (V === N || s(V, N, r, n, i)))
          return I.push(ie);
      })) {
        A = !1;
        break;
      }
    } else if (!(V === D || s(V, D, r, n, i))) {
      A = !1;
      break;
    }
  }
  return i.delete(e), i.delete(t), A;
}
var La = Tm;
function $m(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n, s) {
    r[++t] = [s, n];
  }), r;
}
var Am = $m;
function Sm(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n) {
    r[++t] = n;
  }), r;
}
var Em = Sm, On = Lt, Cn = Ea, wm = xr, xm = La, km = Am, jm = Em, Rm = 1, Om = 2, Cm = "[object Boolean]", Im = "[object Date]", Mm = "[object Error]", Nm = "[object Map]", Pm = "[object Number]", Vm = "[object RegExp]", Dm = "[object Set]", Lm = "[object String]", Um = "[object Symbol]", Zm = "[object ArrayBuffer]", Hm = "[object DataView]", In = On ? On.prototype : void 0, ur = In ? In.valueOf : void 0;
function qm(e, t, r, n, s, i, o) {
  switch (r) {
    case Hm:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case Zm:
      return !(e.byteLength != t.byteLength || !i(new Cn(e), new Cn(t)));
    case Cm:
    case Im:
    case Pm:
      return wm(+e, +t);
    case Mm:
      return e.name == t.name && e.message == t.message;
    case Vm:
    case Lm:
      return e == t + "";
    case Nm:
      var c = km;
    case Dm:
      var u = n & Rm;
      if (c || (c = jm), e.size != t.size && !u)
        return !1;
      var l = o.get(e);
      if (l)
        return l == t;
      n |= Om, o.set(e, t);
      var f = xm(c(e), c(t), n, s, i, o);
      return o.delete(e), f;
    case Um:
      if (ur)
        return ur.call(e) == ur.call(t);
  }
  return !1;
}
var Bm = qm, Mn = Aa, Gm = 1, zm = Object.prototype, Fm = zm.hasOwnProperty;
function Km(e, t, r, n, s, i) {
  var o = r & Gm, c = Mn(e), u = c.length, l = Mn(t), f = l.length;
  if (u != f && !o)
    return !1;
  for (var _ = u; _--; ) {
    var A = c[_];
    if (!(o ? A in t : Fm.call(t, A)))
      return !1;
  }
  var I = i.get(e), V = i.get(t);
  if (I && V)
    return I == t && V == e;
  var D = !0;
  i.set(e, t), i.set(t, e);
  for (var O = o; ++_ < u; ) {
    A = c[_];
    var N = e[A], ie = t[A];
    if (n)
      var tn = o ? n(ie, N, A, t, e, i) : n(N, ie, A, e, t, i);
    if (!(tn === void 0 ? N === ie || s(N, ie, r, n, i) : tn)) {
      D = !1;
      break;
    }
    O || (O = A == "constructor");
  }
  if (D && !O) {
    var mt = e.constructor, gt = t.constructor;
    mt != gt && "constructor" in e && "constructor" in t && !(typeof mt == "function" && mt instanceof mt && typeof gt == "function" && gt instanceof gt) && (D = !1);
  }
  return i.delete(e), i.delete(t), D;
}
var Wm = Km, lr = ua, Qm = La, Jm = Bm, Ym = Wm, Nn = Gt, Pn = Bt, Vn = jr, Xm = ha, eg = 1, Dn = "[object Arguments]", Ln = "[object Array]", _t = "[object Object]", tg = Object.prototype, Un = tg.hasOwnProperty;
function rg(e, t, r, n, s, i) {
  var o = Pn(e), c = Pn(t), u = o ? Ln : Nn(e), l = c ? Ln : Nn(t);
  u = u == Dn ? _t : u, l = l == Dn ? _t : l;
  var f = u == _t, _ = l == _t, A = u == l;
  if (A && Vn(e)) {
    if (!Vn(t))
      return !1;
    o = !0, f = !1;
  }
  if (A && !f)
    return i || (i = new lr()), o || Xm(e) ? Qm(e, t, r, n, s, i) : Jm(e, t, u, r, n, s, i);
  if (!(r & eg)) {
    var I = f && Un.call(e, "__wrapped__"), V = _ && Un.call(t, "__wrapped__");
    if (I || V) {
      var D = I ? e.value() : e, O = V ? t.value() : t;
      return i || (i = new lr()), s(D, O, r, n, i);
    }
  }
  return A ? (i || (i = new lr()), Ym(e, t, r, n, s, i)) : !1;
}
var ng = rg, ag = ng, Zn = je;
function Ua(e, t, r, n, s) {
  return e === t ? !0 : e == null || t == null || !Zn(e) && !Zn(t) ? e !== e && t !== t : ag(e, t, r, n, Ua, s);
}
var sg = Ua, ig = sg;
function og(e, t) {
  return ig(e, t);
}
var cg = og;
const ug = /* @__PURE__ */ na(cg), lg = (e) => ({
  equals(t, r) {
    return t !== void 0 && r !== void 0 ? e.equals(t, r) : t === void 0 && r === void 0;
  }
}), dr = (e, t) => ({
  equals(r, n) {
    return t.equals(e(r), e(n));
  }
}), he = {
  equals(e, t) {
    return ug(e, t);
  }
}, dg = a.object({
  id: a.optional(a.string()),
  // Firebase Firestore ID
  url: a.string(),
  path: a.string(),
  headers: a.array(
    a.object({
      key: a.string(),
      value: a.string(),
      active: a.boolean()
    })
  ),
  params: a.array(
    a.object({
      key: a.string(),
      value: a.string(),
      active: a.boolean()
    })
  ),
  name: a.string(),
  method: a.string(),
  preRequestScript: a.string(),
  testScript: a.string(),
  contentType: a.string(),
  body: a.string(),
  rawParams: a.optional(a.string()),
  auth: a.optional(a.string()),
  httpUser: a.optional(a.string()),
  httpPassword: a.optional(a.string()),
  bearerToken: a.optional(a.string())
}), Hn = S({
  initial: !0,
  schema: dg
}), Dr = a.object({
  key: a.string(),
  active: a.boolean()
}).and(
  a.union([
    a.object({
      isFile: a.literal(!0),
      value: a.array(a.instanceof(Blob).nullable())
    }),
    a.object({
      isFile: a.literal(!1),
      value: a.string()
    })
  ])
);
a.object({
  contentType: a.literal("multipart/form-data"),
  body: a.array(Dr)
});
const fg = a.union([
  a.object({
    contentType: a.literal(null),
    body: a.literal(null).catch(null)
  }),
  a.object({
    contentType: a.literal("multipart/form-data"),
    body: a.array(Dr).catch([])
  }),
  a.object({
    contentType: a.union([
      a.literal("application/json"),
      a.literal("application/ld+json"),
      a.literal("application/hal+json"),
      a.literal("application/vnd.api+json"),
      a.literal("application/xml"),
      a.literal("application/x-www-form-urlencoded"),
      a.literal("text/html"),
      a.literal("text/plain")
    ]),
    body: a.string().catch("")
  })
]), Oe = a.object({
  authType: a.literal("none")
}), Ce = a.object({
  authType: a.literal("basic"),
  username: a.string().catch(""),
  password: a.string().catch("")
}), Ie = a.object({
  authType: a.literal("bearer"),
  token: a.string().catch("")
}), pg = a.object({
  authType: a.literal("oauth-2"),
  token: a.string().catch(""),
  oidcDiscoveryURL: a.string().catch(""),
  authURL: a.string().catch(""),
  accessTokenURL: a.string().catch(""),
  clientID: a.string().catch(""),
  scope: a.string().catch("")
}), it = a.object({
  authType: a.literal("api-key"),
  key: a.string().catch(""),
  value: a.string().catch(""),
  addTo: a.string().catch("Headers")
}), Me = a.object({
  authType: a.literal("inherit")
}), hg = a.discriminatedUnion("authType", [
  Oe,
  Me,
  Ce,
  Ie,
  pg,
  it
]).and(
  a.object({
    authActive: a.boolean()
  })
), vg = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0)
  })
), Za = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0)
  })
), Ha = a.object({
  v: a.literal("1"),
  id: a.optional(a.string()),
  // Firebase Firestore ID
  name: a.string(),
  method: a.string(),
  endpoint: a.string(),
  params: vg,
  headers: Za,
  preRequestScript: a.string().catch(""),
  testScript: a.string().catch(""),
  auth: hg,
  body: fg
});
function mg(e) {
  return {
    contentType: "application/json",
    body: e.contentType === "application/json" ? e.rawParams ?? "" : ""
  };
}
function gg(e) {
  return !e.auth || e.auth === "None" ? {
    authType: "none",
    authActive: !0
  } : e.auth === "Basic Auth" ? {
    authType: "basic",
    authActive: !0,
    username: e.httpUser ?? "",
    password: e.httpPassword ?? ""
  } : e.auth === "Bearer Token" ? {
    authType: "bearer",
    authActive: !0,
    token: e.bearerToken ?? ""
  } : { authType: "none", authActive: !0 };
}
const yg = S({
  initial: !1,
  schema: Ha,
  up(e) {
    const {
      url: t,
      path: r,
      headers: n,
      params: s,
      name: i,
      method: o,
      preRequestScript: c,
      testScript: u
    } = e, l = `${t}${r}`, f = mg(e), _ = gg(e), A = {
      v: "1",
      endpoint: l,
      headers: n,
      params: s,
      name: i,
      method: o,
      preRequestScript: c,
      testScript: u,
      body: f,
      auth: _
    };
    return e.id && (A.id = e.id), A;
  }
}), Ft = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0)
  })
), qa = Ha.extend({
  v: a.literal("2"),
  requestVariables: Ft
}), _g = S({
  initial: !1,
  schema: qa,
  up(e) {
    return {
      ...e,
      v: "2",
      requestVariables: []
    };
  }
}), Ba = a.object({
  grantType: a.literal("AUTHORIZATION_CODE"),
  authEndpoint: a.string().trim(),
  tokenEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  clientSecret: a.string().trim(),
  scopes: a.string().trim().optional(),
  token: a.string().catch(""),
  isPKCE: a.boolean(),
  codeVerifierMethod: a.union([a.literal("plain"), a.literal("S256")]).optional()
}), Kt = a.object({
  grantType: a.literal("CLIENT_CREDENTIALS"),
  authEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  clientSecret: a.string().trim(),
  scopes: a.string().trim().optional(),
  token: a.string().catch("")
}), Wt = a.object({
  grantType: a.literal("PASSWORD"),
  authEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  clientSecret: a.string().trim(),
  scopes: a.string().trim().optional(),
  username: a.string().trim(),
  password: a.string().trim(),
  token: a.string().catch("")
}), Qt = a.object({
  grantType: a.literal("IMPLICIT"),
  authEndpoint: a.string().trim(),
  clientID: a.string().trim(),
  scopes: a.string().trim().optional(),
  token: a.string().catch("")
}), Jt = a.object({
  authType: a.literal("oauth-2"),
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Ba,
    Kt,
    Wt,
    Qt
  ]),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), bg = a.discriminatedUnion("authType", [
  Oe,
  Me,
  Ce,
  Ie,
  Jt,
  it
]).and(
  a.object({
    authActive: a.boolean()
  })
), Ga = qa.extend({
  v: a.literal("3"),
  auth: bg
}), Tg = S({
  initial: !1,
  schema: Ga,
  up(e) {
    if (e.auth.authType === "oauth-2") {
      const { token: t, accessTokenURL: r, scope: n, clientID: s, authURL: i } = e.auth;
      return {
        ...e,
        v: "3",
        auth: {
          ...e.auth,
          authType: "oauth-2",
          grantTypeInfo: {
            grantType: "AUTHORIZATION_CODE",
            authEndpoint: i,
            tokenEndpoint: r,
            clientID: s,
            clientSecret: "",
            scopes: n,
            isPKCE: !1,
            token: t
          },
          addTo: "HEADERS"
        }
      };
    }
    return {
      ...e,
      v: "3",
      auth: {
        ...e.auth
      }
    };
  }
}), za = it.extend({
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), $g = a.discriminatedUnion("authType", [
  Oe,
  Me,
  Ce,
  Ie,
  Jt,
  za
]).and(
  a.object({
    authActive: a.boolean()
  })
), Fa = Ga.extend({
  v: a.literal("4"),
  auth: $g
}), Ag = S({
  schema: Fa,
  initial: !1,
  up(e) {
    return e.auth.authType === "api-key" ? {
      ...e,
      v: "4",
      auth: {
        ...e.auth,
        addTo: e.auth.addTo === "Query params" ? "QUERY_PARAMS" : "HEADERS"
      }
    } : {
      ...e,
      auth: {
        ...e.auth
      },
      v: "4"
    };
  }
}), Ka = Ba.extend({
  clientSecret: a.string().optional()
}), Yt = Jt.extend({
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Ka,
    Kt,
    Wt,
    Qt
  ])
}), Wa = a.discriminatedUnion("authType", [
  Oe,
  Me,
  Ce,
  Ie,
  Yt,
  za
]).and(
  a.object({
    authActive: a.boolean()
  })
), Qa = Fa.extend({
  v: a.literal("5"),
  auth: Wa
}), Sg = S({
  schema: Qa,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "5"
    };
  }
}), Ja = a.union([
  a.object({
    contentType: a.literal(null),
    body: a.literal(null).catch(null)
  }),
  a.object({
    contentType: a.literal("multipart/form-data"),
    body: a.array(Dr).catch([])
  }),
  a.object({
    contentType: a.union([
      a.literal("application/json"),
      a.literal("application/ld+json"),
      a.literal("application/hal+json"),
      a.literal("application/vnd.api+json"),
      a.literal("application/xml"),
      a.literal("text/xml"),
      a.literal("application/x-www-form-urlencoded"),
      a.literal("text/html"),
      a.literal("text/plain")
    ]),
    body: a.string().catch("")
  })
]), Ya = Qa.extend({
  v: a.literal("6"),
  body: Ja
}), Eg = S({
  schema: Ya,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "6"
    };
  }
}), Xa = Ka.extend({
  refreshToken: a.string().optional()
}), es = a.object({
  authType: a.literal("oauth-2"),
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Xa,
    Kt,
    Wt,
    Qt
  ]),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Xt = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0),
    description: a.string().catch("")
  })
), ot = a.array(
  a.object({
    key: a.string().catch(""),
    value: a.string().catch(""),
    active: a.boolean().catch(!0),
    description: a.string().catch("")
  })
), Lr = a.object({
  authType: a.literal("aws-signature"),
  accessKey: a.string().catch(""),
  secretKey: a.string().catch(""),
  region: a.string().catch(""),
  serviceName: a.string().catch(""),
  serviceToken: a.string().optional(),
  signature: a.object({}).optional(),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), ts = a.discriminatedUnion("authType", [
  Oe,
  Me,
  Ce,
  Ie,
  es,
  it,
  Lr
]).and(
  a.object({
    authActive: a.boolean()
  })
), rs = Ya.extend({
  v: a.literal("7"),
  params: Xt,
  headers: ot,
  auth: ts
}), wg = S({
  schema: rs,
  initial: !1,
  up(e) {
    const t = e.params.map((n) => ({
      ...n,
      description: ""
    })), r = e.headers.map((n) => ({
      ...n,
      description: ""
    }));
    return {
      ...e,
      v: "7",
      params: t,
      headers: r
      // no need to update anything for HoppRESTAuth, because the newly added refreshToken is optional
    };
  }
}), xg = {
  // 1xx Informational
  // Request received, continuing process.[2]
  // This class of status code indicates a provisional response, consisting only of the Status-Line and optional headers, and is terminated by an empty line. Since HTTP/1.0 did not define any 1xx status codes, servers must not send a 1xx response to an HTTP/1.0 client except under experimental conditions.
  100: "Continue",
  // This means that the server has received the request headers, and that the client should proceed to send the request body (in the case of a request for which a body needs to be sent; for example, a POST request). If the request body is large, sending it to a server when a request has already been rejected based upon inappropriate headers is inefficient. To have a server check if the request could be accepted based on the request's headers alone, a client must send Expect: 100-continue as a header in its initial request[2] and check if a 100 Continue status code is received in response before continuing (or receive 417 Expectation Failed and not continue).[2]
  101: "Switching Protocols",
  // This means the requester has asked the server to switch protocols and the server is acknowledging that it will do so.[2]
  102: "Processing",
  // (WebDAV; RFC 2518) As a WebDAV request may contain many sub-requests involving file operations, it may take a long time to complete the request. This code indicates that the server has received and is processing the request, but no response is available yet.[3] This prevents the client from timing out and assuming the request was lost.
  // 2xx Success
  // This class of status codes indicates the action requested by the client was received, understood, accepted and processed successfully.
  200: "OK",
  // Standard response for successful HTTP requests. The actual response will depend on the request method used. In a GET request, the response will contain an entity corresponding to the requested resource. In a POST request the response will contain an entity describing or containing the result of the action.[2]
  201: "Created",
  // The request has been fulfilled and resulted in a new resource being created.[2]
  202: "Accepted",
  // The request has been accepted for processing, but the processing has not been completed. The request might or might not eventually be acted upon, as it might be disallowed when processing actually takes place.[2]
  203: "Non-Authoritative Information",
  // (since HTTP/1.1) The server successfully processed the request, but is returning information that may be from another source.[2]
  204: "No Content",
  // The server successfully processed the request, but is not returning any content.[2]
  205: "Reset Content",
  // The server successfully processed the request, but is not returning any content. Unlike a 204 response, this response requires that the requester reset the document view.[2]
  206: "Partial Content",
  // The server is delivering only part of the resource due to a range header sent by the client. The range header is used by tools like wget to enable resuming of interrupted downloads, or split a download into multiple simultaneous streams.[2]
  207: "Multi-Status",
  // (WebDAV; RFC 4918) The message body that follows is an XML message and can contain a number of separate response codes, depending on how many sub-requests were made.[4]
  208: "Already Reported",
  // (WebDAV; RFC 5842) The members of a DAV binding have already been enumerated in a previous reply to this request, and are not being included again.
  226: "IM Used",
  // (RFC 3229) The server has fulfilled a GET request for the resource, and the response is a representation of the result of one or more instance-manipulations applied to the current instance. [5]
  // 3xx Redirection
  // The client must take additional action to complete the request.[2]
  // This class of status code indicates that further action needs to be taken by the user agent in order to fulfil the request. The action required may be carried out by the user agent without interaction with the user if and only if the method used in the second request is GET or HEAD. A user agent should not automatically redirect a request more than five times, since such redirections usually indicate an infinite loop.
  300: "Multiple Choices",
  // Indicates multiple options for the resource that the client may follow. It, for instance, could be used to present different format options for video, list files with different extensions, or word sense disambiguation.[2]
  301: "Moved Permanently",
  // This and all future requests should be directed to the given URI.[2]
  302: "Found",
  // This is an example of industry practice contradicting the standard.[2] The HTTP/1.0 specification (RFC 1945) required the client to perform a temporary redirect (the original describing phrase was "Moved Temporarily"),[6] but popular browsers implemented 302 with the functionality of a 303 See Other. Therefore, HTTP/1.1 added status codes 303 and 307 to distinguish between the two behaviours.[7] However, some Web applications and frameworks use the 302 status code as if it were the 303.[citation needed]
  303: "See Other",
  // (since HTTP/1.1) The response to the request can be found under another URI using a GET method. When received in response to a POST (or PUT/DELETE), it should be assumed that the server has received the data and the redirect should be issued with a separate GET message.[2]
  304: "Not Modified",
  // Indicates the resource has not been modified since last requested.[2] Typically, the HTTP client provides a header like the If-Modified-Since header to provide a time against which to compare. Using this saves bandwidth and reprocessing on both the server and client, as only the header data must be sent and received in comparison to the entirety of the page being re-processed by the server, then sent again using more bandwidth of the server and client.
  305: "Use Proxy",
  // (since HTTP/1.1) Many HTTP clients (such as Mozilla[8] and Internet Explorer) do not correctly handle responses with this status code, primarily for security reasons.[2]
  306: "Switch Proxy",
  // No longer used.[2] Originally meant "Subsequent requests should use the specified proxy."[9]
  307: "Temporary Redirect",
  // (since HTTP/1.1) In this case, the request should be repeated with another URI; however, future requests can still use the original URI.[2] In contrast to 302, the request method should not be changed when reissuing the original request. For instance, a POST request must be repeated using another POST request.
  308: "Permanent Redirect",
  // (experimental Internet-Draft)[10] The request, and all future requests should be repeated using another URI. 307 and 308 (as proposed) parallel the behaviours of 302 and 301, but do not require the HTTP method to change. So, for example, submitting a form to a permanently redirected resource may continue smoothly.
  // 4xx Client Error
  // The 4xx class of status code is intended for cases in which the client seems to have erred. Except when responding to a HEAD request, the server should include an entity containing an explanation of the error situation, and whether it is a temporary or permanent condition. These status codes are applicable to any request method. User agents should display any included entity to the user.
  400: "Bad Request",
  // The request cannot be fulfilled due to bad syntax.[2]
  401: "Unauthorized",
  // Similar to 403 Forbidden, but specifically for use when authentication is possible but has failed or not yet been provided.[2] The response must include a WWW-Authenticate header field containing a challenge applicable to the requested resource. See Basic access authentication and Digest access authentication.
  402: "Payment Required",
  // Reserved for future use.[2] The original intention was that this code might be used as part of some form of digital cash or micropayment scheme, but that has not happened, and this code is not usually used. As an example of its use, however, Apple's MobileMe service generates a 402 error ("httpStatusCode:402" in the Mac OS X Console log) if the MobileMe account is delinquent.[citation needed]
  403: "Forbidden",
  // The request was a legal request, but the server is refusing to respond to it.[2] Unlike a 401 Unauthorized response, authenticating will make no difference.[2]
  404: "Not Found",
  // The requested resource could not be found but may be available again in the future.[2] Subsequent requests by the client are permissible.
  405: "Method Not Allowed",
  // A request was made of a resource using a request method not supported by that resource;[2] for example, using GET on a form which requires data to be presented via POST, or using PUT on a read-only resource.
  406: "Not Acceptable",
  // The requested resource is only capable of generating content not acceptable according to the Accept headers sent in the request.[2]
  407: "Proxy Authentication Required",
  // The client must first authenticate itself with the proxy.[2]
  408: "Request Timeout",
  // The server timed out waiting for the request.[2] According to W3 HTTP specifications: "The client did not produce a request within the time that the server was prepared to wait. The client MAY repeat the request without modifications at any later time."
  409: "Conflict",
  // Indicates that the request could not be processed because of conflict in the request, such as an edit conflict.[2]
  410: "Gone",
  // Indicates that the resource requested is no longer available and will not be available again.[2] This should be used when a resource has been intentionally removed and the resource should be purged. Upon receiving a 410 status code, the client should not request the resource again in the future. Clients such as search engines should remove the resource from their indices. Most use cases do not require clients and search engines to purge the resource, and a "404 Not Found" may be used instead.
  411: "Length Required",
  // The request did not specify the length of its content, which is required by the requested resource.[2]
  412: "Precondition Failed",
  // The server does not meet one of the preconditions that the requester put on the request.[2]
  413: "Request Entity Too Large",
  // The request is larger than the server is willing or able to process.[2]
  414: "Request-URI Too Long",
  // The URI provided was too long for the server to process.[2]
  415: "Unsupported Media Type",
  // The request entity has a media type which the server or resource does not support.[2] For example, the client uploads an image as image/svg+xml, but the server requires that images use a different format.
  416: "Requested Range Not Satisfiable",
  // The client has asked for a portion of the file, but the server cannot supply that portion.[2] For example, if the client asked for a part of the file that lies beyond the end of the file.[2]
  417: "Expectation Failed",
  // The server cannot meet the requirements of the Expect request-header field.[2]
  418: "I'm a teapot",
  // (RFC 2324) This code was defined in 1998 as one of the traditional IETF April Fools' jokes, in RFC 2324, Hyper Text Coffee Pot Control Protocol, and is not expected to be implemented by actual HTTP servers. However, known implementations do exist.[11]
  420: "Enhance Your Calm",
  // (Twitter) Returned by the Twitter Search and Trends API when the client is being rate limited.[12] Likely a reference to this number's association with marijuana. Other services may wish to implement the 429 Too Many Requests response code instead. The phrase "Enhance Your Calm" is a reference to Demolition Man (film). In the film, Sylvester Stallone's character John Spartan is a hot-head in a generally more subdued future, and is regularly told to "Enhance your calm" rather than a more common phrase like "calm down".
  422: "Unprocessable Entity",
  // (WebDAV; RFC 4918) The request was well-formed but was unable to be followed due to semantic errors.[4]
  423: "Locked",
  // (WebDAV; RFC 4918) The resource that is being accessed is locked.[4]
  424: "Failed Dependency",
  // (WebDAV; RFC 4918) The request failed due to failure of a previous request (e.g. a PROPPATCH).[4]
  425: "Unordered Collection",
  // (Internet draft) Defined in drafts of "WebDAV Advanced Collections Protocol",[14] but not present in "Web Distributed Authoring and Versioning (WebDAV) Ordered Collections Protocol".[15]
  426: "Upgrade Required",
  // (RFC 2817) The client should switch to a different protocol such as TLS/1.0.[16]
  428: "Precondition Required",
  // (RFC 6585) The origin server requires the request to be conditional. Intended to prevent "the 'lost update' problem, where a client GETs a resource's state, modifies it, and PUTs it back to the server, when meanwhile a third party has modified the state on the server, leading to a conflict."[17]
  429: "Too Many Requests",
  // (RFC 6585) The user has sent too many requests in a given amount of time. Intended for use with rate limiting schemes.[17]
  431: "Request Header Fields Too Large",
  // (RFC 6585) The server is unwilling to process the request because either an individual header field, or all the header fields collectively, are too large.[17]
  444: "No Response",
  // (Nginx) Used in Nginx logs to indicate that the server has returned no information to the client and closed the connection (useful as a deterrent for malware).
  449: "Retry With",
  // (Microsoft) A Microsoft extension. The request should be retried after performing the appropriate action.[18] Often search-engines or custom applications will ignore required parameters. Where no default action is appropriate, the Aviongoo website sends a "HTTP/1.1 449 Retry with valid parameters: param1, param2, . . ." response. The applications may choose to learn, or not.
  450: "Blocked by Windows Parental Controls",
  // (Microsoft) A Microsoft extension. This error is given when Windows Parental Controls are turned on and are blocking access to the given webpage.[19]
  451: "Unavailable For Legal Reasons",
  // (Internet draft) Defined in the internet draft "A New HTTP Status Code for Legally-restricted Resources",[20]. Intended to be used when resource access is denied for legal reasons, e.g. censorship or government-mandated blocked access. Likely a reference to the 1953 dystopian novel Fahrenheit 451, where books are outlawed.
  499: "Client Closed Request",
  // (Nginx) Used in Nginx logs to indicate when the connection has been closed by client while the server is still processing its request, making server unable to send a status code back.[21]
  // 5xx Server Error
  // The server failed to fulfill an apparently valid request.[2]
  // Response status codes beginning with the digit "5" indicate cases in which the server is aware that it has encountered an error or is otherwise incapable of performing the request. Except when responding to a HEAD request, the server should include an entity containing an explanation of the error situation, and indicate whether it is a temporary or permanent condition. Likewise, user agents should display any included entity to the user. These response codes are applicable to any request method.
  500: "Internal Server Error",
  // A generic error message, given when no more specific message is suitable.[2]
  501: "Not Implemented",
  // The server either does not recognise the request method, or it lacks the ability to fulfill the request.[2]
  502: "Bad Gateway",
  // The server was acting as a gateway or proxy and received an invalid response from the upstream server.[2]
  503: "Service Unavailable",
  // The server is currently unavailable (because it is overloaded or down for maintenance).[2] Generally, this is a temporary state.
  504: "Gateway Timeout",
  // The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.[2]
  505: "HTTP Version Not Supported",
  // The server does not support the HTTP protocol version used in the request.[2]
  506: "Variant Also Negotiates",
  // (RFC 2295) Transparent content negotiation for the request results in a circular reference.[22]
  507: "Insufficient Storage",
  // (WebDAV; RFC 4918) The server is unable to store the representation needed to complete the request.[4]
  508: "Loop Detected",
  // (WebDAV; RFC 5842) The server detected an infinite loop while processing the request (sent in lieu of 208).
  509: "Bandwidth Limit Exceeded",
  // (Apache bw/limited extension) This status code, while used by many servers, is not specified in any RFCs.
  510: "Not Extended",
  // (RFC 2774) Further extensions to the request are required for the server to fulfill it.[23]
  511: "Network Authentication Required",
  // (RFC 6585) The client needs to authenticate to gain network access. Intended for use by intercepting proxies used to control access to the network (e.g. "captive portals" used to require agreement to Terms of Service before granting full Internet access via a Wi-Fi hotspot).[17]
  598: "Network read timeout error",
  // (Unknown) This status code is not specified in any RFCs, but is used by Microsoft Corp. HTTP proxies to signal a network read timeout behind the proxy to a client in front of the proxy.
  599: "Network connect timeout error"
  // (Unknown) This status code is not specified in any RFCs, but is used by Microsoft Corp. HTTP proxies to signal a network connect timeout behind the proxy to a client in front of the proxy.
}, kg = Kt.extend({
  clientSecret: a.string().optional()
}), jg = Wt.extend({
  clientSecret: a.string().optional()
}), Rg = a.object({
  authType: a.literal("oauth-2"),
  grantTypeInfo: a.discriminatedUnion("grantType", [
    Xa,
    kg,
    jg,
    Qt
  ]),
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Og = a.object({
  authType: a.literal("digest"),
  username: a.string().catch(""),
  password: a.string().catch(""),
  realm: a.string().catch(""),
  nonce: a.string().catch(""),
  algorithm: a.enum(["MD5", "MD5-sess"]).catch("MD5"),
  qop: a.enum(["auth", "auth-int"]).catch("auth"),
  nc: a.string().catch(""),
  cnonce: a.string().catch(""),
  opaque: a.string().catch(""),
  disableRetry: a.boolean().catch(!1)
}), ct = a.discriminatedUnion("authType", [
  Oe,
  Me,
  Ce,
  Ie,
  Rg,
  it,
  Lr,
  Og
]).and(
  a.object({
    authActive: a.boolean()
  })
), ns = a.union(
  Object.keys(xg).map((e) => a.literal(parseInt(e)))
), as = a.array(
  a.object({
    key: a.string(),
    value: a.string()
  })
), Cg = a.object({
  v: a.literal("1"),
  name: a.string(),
  method: a.string(),
  endpoint: a.string(),
  headers: ot,
  params: Xt,
  body: Ja,
  auth: ct,
  requestVariables: Ft
}), Ig = a.object({
  name: a.string(),
  originalRequest: Cg,
  status: a.string(),
  code: a.optional(ns),
  headers: as,
  body: a.string()
}), Mg = a.record(
  a.string(),
  Ig
), ss = rs.extend({
  v: a.literal("8"),
  auth: ct,
  responses: Mg
}), Ng = S({
  schema: ss,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "8",
      // no need to update anything for HoppRESTAuth, because we loosened the previous schema by making `clientSecret` optional
      responses: {}
    };
  }
}), is = a.object({
  key: a.string(),
  active: a.boolean(),
  contentType: a.string().optional().catch(void 0)
}).and(
  a.union([
    a.object({
      isFile: a.literal(!0),
      value: a.array(a.instanceof(Blob).nullable())
    }),
    a.object({
      isFile: a.literal(!1),
      value: a.string()
    })
  ])
), os = a.union([
  a.object({
    contentType: a.literal(null),
    body: a.literal(null).catch(null)
  }),
  a.object({
    contentType: a.literal("multipart/form-data"),
    body: a.array(is).catch([]),
    showIndividualContentType: a.boolean().optional().catch(!1)
  }),
  a.object({
    contentType: a.literal("application/octet-stream"),
    body: a.instanceof(File).nullable().catch(null)
  }),
  a.object({
    contentType: a.union([
      a.literal("application/json"),
      a.literal("application/ld+json"),
      a.literal("application/hal+json"),
      a.literal("application/vnd.api+json"),
      a.literal("application/xml"),
      a.literal("text/xml"),
      a.literal("application/x-www-form-urlencoded"),
      a.literal("binary"),
      a.literal("text/html"),
      a.literal("text/plain")
    ]),
    body: a.string().catch("")
  })
]), Pg = a.object({
  v: a.literal("2"),
  name: a.string(),
  method: a.string(),
  endpoint: a.string(),
  headers: ot,
  params: Xt,
  body: os,
  auth: ct,
  requestVariables: Ft
}), Vg = a.object({
  name: a.string(),
  originalRequest: Pg,
  status: a.string(),
  code: a.optional(ns),
  headers: as,
  body: a.string()
}), cs = a.record(
  a.string(),
  Vg
), us = ss.extend({
  v: a.literal("9"),
  body: os,
  responses: cs
}), Dg = S({
  schema: us,
  initial: !1,
  up(e) {
    const t = Object.fromEntries(
      Object.entries(e.responses).map(([r, n]) => [
        r,
        {
          ...n,
          originalRequest: {
            ...n.originalRequest,
            v: "2"
          }
        }
      ])
    );
    return {
      ...e,
      v: "9",
      responses: t
    };
  }
}), ls = a.union([
  a.object({
    contentType: a.literal(null),
    body: a.literal(null).catch(null)
  }),
  a.object({
    contentType: a.literal("multipart/form-data"),
    body: a.array(is).catch([]),
    showIndividualContentType: a.boolean().optional().catch(!1),
    isBulkEditing: a.boolean().optional().catch(!1)
  }),
  a.object({
    contentType: a.literal("application/octet-stream"),
    body: a.instanceof(File).nullable().catch(null)
  }),
  a.object({
    contentType: a.literal("application/x-www-form-urlencoded"),
    body: a.string().catch(""),
    isBulkEditing: a.boolean().optional().catch(!1)
  }),
  a.object({
    contentType: a.union([
      a.literal("application/json"),
      a.literal("application/ld+json"),
      a.literal("application/hal+json"),
      a.literal("application/vnd.api+json"),
      a.literal("application/xml"),
      a.literal("text/xml"),
      a.literal("binary"),
      a.literal("text/html"),
      a.literal("text/plain")
    ]),
    body: a.string().catch("")
  })
]), Lg = us.extend({
  v: a.literal("10"),
  body: ls
}), Ug = S({
  schema: Lg,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: "10"
    };
  }
}), Zg = {
  "application/json": "json",
  "application/ld+json": "json",
  "application/hal+json": "json",
  "application/vnd.api+json": "json",
  "application/xml": "xml",
  "text/xml": "xml",
  "application/x-www-form-urlencoded": "multipart",
  "multipart/form-data": "multipart",
  "application/octet-stream": "binary",
  "text/html": "html",
  "text/plain": "plain"
}, q_ = Object.keys(
  Zg
), Hg = a.object({
  // v is a stringified number
  v: a.string().regex(/^\d+$/).transform(Number)
}), er = st({
  latestVersion: 10,
  versionMap: {
    0: Hn,
    1: yg,
    2: _g,
    3: Tg,
    4: Ag,
    5: Sg,
    6: Eg,
    7: wg,
    8: Ng,
    9: Dg,
    10: Ug
  },
  getVersion(e) {
    const t = Hg.safeParse(e);
    return t.success ? t.data.v : Hn.schema.safeParse(e).success ? 0 : null;
  }
}), qg = oi({
  id: lg(Q),
  v: Q,
  auth: he,
  body: he,
  endpoint: Q,
  headers: dr(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    he
  ),
  params: dr(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    he
  ),
  method: Q,
  name: Q,
  preRequestScript: Q,
  testScript: Q,
  requestVariables: dr(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    he
  ),
  responses: he
}), ds = "10", B_ = qg.equals;
function G_(e, t) {
  const r = Xh(t);
  if (e && typeof e == "object") {
    if ("id" in e && typeof e.id == "string" && (r.id = e.id), "name" in e && typeof e.name == "string" && (r.name = e.name), "method" in e && typeof e.method == "string" && (r.method = e.method), "endpoint" in e && typeof e.endpoint == "string" && (r.endpoint = e.endpoint), "preRequestScript" in e && typeof e.preRequestScript == "string" && (r.preRequestScript = e.preRequestScript), "testScript" in e && typeof e.testScript == "string" && (r.testScript = e.testScript), "body" in e) {
      const n = ls.safeParse(e.body);
      n.success && (r.body = n.data);
    }
    if ("auth" in e) {
      const n = ct.safeParse(e.auth);
      n.success && (r.auth = n.data);
    }
    if ("params" in e) {
      const n = Xt.safeParse(e.params);
      n.success && (r.params = n.data);
    }
    if ("headers" in e) {
      const n = ot.safeParse(e.headers);
      n.success && (r.headers = n.data);
    }
    if ("requestVariables" in e) {
      const n = Ft.safeParse(e.requestVariables);
      n.success && (r.requestVariables = n.data);
    }
    if ("responses" in e) {
      const n = cs.safeParse(e.responses);
      n.success && (r.responses = n.data);
    }
  }
  return r;
}
function z_(e) {
  return {
    v: ds,
    ...e
  };
}
function Bg() {
  return {
    v: ds,
    endpoint: "https://echo.hoppscotch.io",
    name: "Untitled",
    params: [],
    headers: [],
    method: "GET",
    auth: {
      authType: "inherit",
      authActive: !0
    },
    preRequestScript: "",
    testScript: "",
    body: {
      contentType: null,
      body: null
    },
    requestVariables: [],
    responses: {}
  };
}
function F_(e) {
  return er.isLatest(e);
}
function Gg(e) {
  const t = er.safeParse(e);
  return t.type === "ok" ? t.value : Bg();
}
const Ur = a.object({
  key: a.string().catch(""),
  value: a.string().catch(""),
  active: a.boolean().catch(!0)
}), zg = a.object({
  v: a.literal(1),
  name: a.string(),
  url: a.string(),
  headers: a.array(Ur).catch([]),
  query: a.string(),
  variables: a.string()
}), Fg = S({
  initial: !0,
  schema: zg
}), Ne = a.object({
  authType: a.literal("none")
}), Pe = a.object({
  authType: a.literal("basic"),
  username: a.string().catch(""),
  password: a.string().catch("")
}), Ve = a.object({
  authType: a.literal("bearer"),
  token: a.string().catch("")
}), Kg = a.object({
  authType: a.literal("oauth-2"),
  token: a.string().catch(""),
  oidcDiscoveryURL: a.string().catch(""),
  authURL: a.string().catch(""),
  accessTokenURL: a.string().catch(""),
  clientID: a.string().catch(""),
  scope: a.string().catch("")
}), Zr = a.object({
  authType: a.literal("api-key"),
  key: a.string().catch(""),
  value: a.string().catch(""),
  addTo: a.string().catch("Headers")
}), De = a.object({
  authType: a.literal("inherit")
}), Wg = a.discriminatedUnion("authType", [
  Ne,
  Pe,
  Ve,
  Kg,
  Zr,
  De
]).and(
  a.object({
    authActive: a.boolean()
  })
), fs = a.object({
  id: a.optional(a.string()),
  v: a.literal(2),
  name: a.string(),
  url: a.string(),
  headers: a.array(Ur).catch([]),
  query: a.string(),
  variables: a.string(),
  auth: Wg
}), Qg = S({
  initial: !1,
  schema: fs,
  up(e) {
    return {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "none"
      }
    };
  }
}), Jg = a.discriminatedUnion("authType", [
  Ne,
  De,
  Pe,
  Ve,
  Zr,
  Jt
  // both rest and gql have the same auth type for oauth2
]).and(
  a.object({
    authActive: a.boolean()
  })
), ps = fs.extend({
  v: a.literal(3),
  auth: Jg
}), Yg = S({
  initial: !1,
  schema: ps,
  up(e) {
    if (e.auth.authType === "oauth-2") {
      const { token: t, accessTokenURL: r, scope: n, clientID: s, authURL: i } = e.auth;
      return {
        ...e,
        v: 3,
        auth: {
          ...e.auth,
          authType: "oauth-2",
          grantTypeInfo: {
            grantType: "AUTHORIZATION_CODE",
            authEndpoint: i,
            tokenEndpoint: r,
            clientID: s,
            clientSecret: "",
            scopes: n,
            isPKCE: !1,
            token: t
          },
          addTo: "HEADERS"
        }
      };
    }
    return {
      ...e,
      v: 3,
      auth: {
        ...e.auth
      }
    };
  }
}), tr = Zr.extend({
  addTo: a.enum(["HEADERS", "QUERY_PARAMS"]).catch("HEADERS")
}), Xg = a.discriminatedUnion("authType", [
  Ne,
  De,
  Pe,
  Ve,
  tr,
  Yt
  // both rest and gql have the same auth type for oauth2
]).and(
  a.object({
    authActive: a.boolean()
  })
), hs = ps.extend({
  v: a.literal(4),
  auth: Xg
}), ey = S({
  initial: !1,
  schema: hs,
  up(e) {
    return e.auth.authType === "api-key" ? {
      ...e,
      v: 4,
      auth: {
        ...e.auth,
        addTo: e.auth.addTo === "Query params" ? "QUERY_PARAMS" : "HEADERS"
      }
    } : {
      ...e,
      v: 4,
      auth: {
        ...e.auth
      }
    };
  }
}), vs = a.discriminatedUnion("authType", [
  Ne,
  De,
  Pe,
  Ve,
  tr,
  Yt
  // both rest and gql have the same auth type for oauth2
]).and(
  a.object({
    authActive: a.boolean()
  })
), ms = hs.extend({
  v: a.literal(5),
  auth: vs
}), ty = S({
  initial: !1,
  schema: ms,
  up(e) {
    return {
      ...e,
      v: 5
    };
  }
}), gs = Lr, ys = a.object({
  key: a.string().catch(""),
  value: a.string().catch(""),
  active: a.boolean().catch(!0),
  description: a.string().catch("")
}), _s = a.discriminatedUnion("authType", [
  Ne,
  De,
  Pe,
  Ve,
  Yt,
  tr,
  gs
]).and(
  a.object({
    authActive: a.boolean()
  })
), bs = ms.extend({
  v: a.literal(6),
  auth: _s,
  headers: a.array(ys).catch([])
}), ry = S({
  schema: bs,
  initial: !1,
  up(e) {
    const t = e.headers.map((r) => ({
      ...r,
      description: ""
    }));
    return {
      ...e,
      v: 6,
      headers: t
    };
  }
}), Ts = a.discriminatedUnion("authType", [
  Ne,
  De,
  Pe,
  Ve,
  es,
  tr,
  gs
]).and(
  a.object({
    authActive: a.boolean()
  })
), ny = bs.extend({
  v: a.literal(7),
  auth: Ts
}), ay = S({
  schema: ny,
  initial: !1,
  up(e) {
    return {
      ...e,
      v: 7
      // no need to update anything for HoppGQLAuth, because we loosened the previous schema by making `clientSecret` optional
    };
  }
}), $s = 7, sy = a.object({
  v: a.number()
}), Hr = st({
  latestVersion: 7,
  versionMap: {
    1: Fg,
    2: Qg,
    3: Yg,
    4: ey,
    5: ty,
    6: ry,
    7: ay
  },
  getVersion(e) {
    const t = sy.safeParse(e);
    return t.success ? t.data.v : null;
  }
}), iy = `
query Request {
  method
  url
  headers {
    key
    value
  }
}`.trim();
function oy() {
  return {
    v: $s,
    name: "Untitled",
    url: "https://echo.hoppscotch.io/graphql",
    headers: [],
    variables: `
{
  "id": "1"
}`.trim(),
    query: iy,
    auth: {
      authType: "inherit",
      authActive: !0
    }
  };
}
function cy(e) {
  const t = Hr.safeParse(e);
  return t.type === "ok" ? t.value : oy();
}
function K_(e) {
  return {
    v: $s,
    ...e
  };
}
const uy = a.object({
  v: a.literal(1),
  id: a.optional(a.string()),
  // For Firestore ID data
  name: a.string(),
  requests: a.array(
    a.lazy(
      () => a.union([
        Ct(er),
        Ct(Hr)
      ])
    )
  )
}), As = uy.extend({
  folders: a.lazy(() => a.array(As))
}), qn = S({
  initial: !0,
  schema: As
}), Ss = a.object({
  v: a.literal(2),
  id: a.optional(a.string()),
  // For Firestore ID data
  name: a.string(),
  requests: a.array(
    a.lazy(
      () => a.union([
        Ct(er),
        Ct(Hr)
      ])
    )
  ),
  auth: a.union([Wa, vs]),
  headers: a.union([Za, a.array(Ur)])
}), Es = Ss.extend({
  folders: a.lazy(() => a.array(Es))
}), ly = S({
  initial: !1,
  schema: Es,
  up(e) {
    const t = {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "inherit"
      },
      headers: []
    };
    return e.id && (t.id = e.id), t;
  }
}), ws = Ss.extend({
  v: a.literal(3),
  // AWS Signature Authorization type addition
  auth: a.union([ts, _s]),
  // `description` field addition under `headers`
  headers: a.union([ot, a.array(ys)])
}), xs = ws.extend({
  folders: a.lazy(() => a.array(xs))
}), dy = S({
  initial: !1,
  schema: xs,
  up(e) {
    const t = e.headers.map(
      (n) => ({
        ...n,
        description: ""
      })
    );
    return {
      ...e,
      v: 3,
      headers: t
    };
  }
}), ks = ws.extend({
  v: a.literal(4),
  auth: a.union([ct, Ts])
}), js = ks.extend({
  folders: a.lazy(() => a.array(js))
}), fy = S({
  initial: !1,
  schema: js,
  // @ts-expect-error
  up(e) {
    return {
      ...e,
      v: 4
    };
  }
});
var C = [];
for (var fr = 0; fr < 256; ++fr)
  C.push((fr + 256).toString(16).slice(1));
function py(e, t = 0) {
  return (C[e[t + 0]] + C[e[t + 1]] + C[e[t + 2]] + C[e[t + 3]] + "-" + C[e[t + 4]] + C[e[t + 5]] + "-" + C[e[t + 6]] + C[e[t + 7]] + "-" + C[e[t + 8]] + C[e[t + 9]] + "-" + C[e[t + 10]] + C[e[t + 11]] + C[e[t + 12]] + C[e[t + 13]] + C[e[t + 14]] + C[e[t + 15]]).toLowerCase();
}
var bt, hy = new Uint8Array(16);
function vy() {
  if (!bt && (bt = typeof crypto < "u" && crypto.getRandomValues && crypto.getRandomValues.bind(crypto), !bt))
    throw new Error("crypto.getRandomValues() not supported. See https://github.com/uuidjs/uuid#getrandomvalues-not-supported");
  return bt(hy);
}
var my = typeof crypto < "u" && crypto.randomUUID && crypto.randomUUID.bind(crypto);
const Bn = {
  randomUUID: my
};
function gy(e, t, r) {
  if (Bn.randomUUID && !t && !e)
    return Bn.randomUUID();
  e = e || {};
  var n = e.random || (e.rng || vy)();
  return n[6] = n[6] & 15 | 64, n[8] = n[8] & 63 | 128, py(n);
}
const Rs = (e = "") => {
  const t = Date.now().toString(36), r = gy();
  return `${e}_${t}_${r}`;
}, yy = ks.extend({
  v: a.literal(5),
  _ref_id: a.string().optional()
}), Os = yy.extend({
  folders: a.lazy(() => a.array(Os))
}), _y = S({
  initial: !1,
  schema: Os,
  // @ts-expect-error
  up(e) {
    return {
      ...e,
      v: 5,
      _ref_id: Rs("coll")
    };
  }
}), by = a.object({
  v: a.number()
}), W_ = st({
  latestVersion: 5,
  versionMap: {
    1: qn,
    2: ly,
    3: dy,
    4: fy,
    5: _y
  },
  getVersion(e) {
    const t = by.safeParse(e);
    return t.success ? t.data.v : qn.schema.safeParse(e).success ? 1 : null;
  }
}), Ty = 5;
function Cs(e) {
  return {
    v: Ty,
    ...e,
    _ref_id: e._ref_id ? e._ref_id : Rs("coll")
  };
}
function $y(e) {
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map($y), n = (e.requests ?? []).map(Gg), s = e.auth ?? { authType: "inherit", authActive: !0 }, i = e.headers ?? [], o = Cs({
    name: t,
    folders: r,
    requests: n,
    auth: s,
    headers: i
  });
  return e.id && (o.id = e.id), e._ref_id && (o._ref_id = e._ref_id), o;
}
function Ay(e) {
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(Ay), n = (e.requests ?? []).map(cy), s = e.auth ?? { authType: "inherit", authActive: !0 }, i = e.headers ?? [], o = Cs({
    name: t,
    folders: r,
    requests: n,
    auth: s,
    headers: i
  });
  return e.id && (o.id = e.id), e._ref_id && (o._ref_id = e._ref_id), o;
}
var ae = function(e) {
  return function(t) {
    return !e(t);
  };
}, Sy = $i, Ey = bi;
function Is(e, t) {
  return t === void 0 ? function(r) {
    return Is(e, r);
  } : Ey(e, t) ? Xn : ea(t[e]);
}
var Ms = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, wy = function(e) {
  return function(t) {
    for (var r = [], n = 0; n < t.length; n++) {
      var s = e(n, t[n]);
      Yn(s) && r.push(s.value);
    }
    return r;
  };
}, xy = function(e) {
  return wy(function(t, r) {
    return e(r);
  });
}, Ns = function(e) {
  return e.slice();
}, ky = _i, Gn = function(e, t, r) {
  if (r || arguments.length === 2) for (var n = 0, s = t.length, i; n < s; n++)
    (i || !(n in t)) && (i || (i = Array.prototype.slice.call(t, 0, n)), i[n] = t[n]);
  return e.concat(i || Array.prototype.slice.call(t));
}, jy = function(e) {
  return function(t) {
    return Gn(Gn([], t, !0), [e], !1);
  };
}, Ry = jy, Oy = function(e) {
  return [e];
}, Cy = Ry, Iy = Is, My = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, Ny = function() {
  return {
    concat: function(e, t) {
      return e.concat(t);
    }
  };
}, Py = function() {
  return {
    concat: Ny().concat,
    empty: []
  };
}, rr = Xn, ut = ea;
function Ps(e) {
  return function(t) {
    return e(t) ? ut(t) : rr;
  };
}
var $r = function(e) {
  return function(t) {
    return Vs(t) ? rr : ut(e(t.value));
  };
}, Vy = Yn, Vs = function(e) {
  return e._tag === "None";
}, Dy = function(e, t) {
  return function(r) {
    return Vs(r) ? e() : t(r.value);
  };
}, Ly = Dy, qr = Ly, Uy = function(e, t) {
  for (var r = t(e); r._tag === "Left"; )
    r = t(r.left);
  return r.right;
}, se = li, z = di, Zy = /* @__PURE__ */ si(2, function(e, t) {
  return Te(e) ? e : t(e.right);
}), lt = function(e) {
  return function(t) {
    return Te(t) ? t : z(e(t.right));
  };
}, nr = function(e) {
  return function(t) {
    return Te(t) ? se(e(t.left)) : t;
  };
}, Te = ci, Hy = ui, qy = function(e) {
  return function(t) {
    return Te(t) ? e(t.left) : t.right;
  };
}, Br = qy, zn = Zy, Mt = function() {
  return Mt = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var s in t) Object.prototype.hasOwnProperty.call(t, s) && (e[s] = t[s]);
    }
    return e;
  }, Mt.apply(this, arguments);
}, dt = function(e, t, r) {
  return z({
    value: e,
    next: t,
    start: r
  });
}, ft = function(e, t, r) {
  return t === void 0 && (t = []), r === void 0 && (r = !1), se({
    input: e,
    expected: t,
    fatal: r
  });
}, By = function(e, t) {
  return Mt(Mt({}, e), { expected: t });
}, Gy = function(e, t) {
  return zy().concat(e, t);
}, zy = function() {
  return {
    concat: function(e, t) {
      return e.input.cursor < t.input.cursor ? gi().concat(e, t) : e.input.cursor > t.input.cursor ? ir().concat(e, t) : mi({
        input: ir(),
        fatal: ir(),
        expected: Py()
      }).concat(e, t);
    }
  };
}, Fy = function(e, t) {
  return t === void 0 && (t = 0), {
    buffer: e,
    cursor: t
  };
}, Ky = function(e) {
  return Iy(e.cursor, e.buffer);
}, Wy = function(e) {
  return e.cursor >= e.buffer.length;
}, Qy = function(e) {
  return y(Ky(e), $r(function(t) {
    return { value: t, next: { buffer: e.buffer, cursor: e.cursor + 1 } };
  }));
}, ne = function() {
  return ne = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var s in t) Object.prototype.hasOwnProperty.call(t, s) && (e[s] = t[s]);
    }
    return e;
  }, ne.apply(this, arguments);
}, Gr = function(e) {
  return function(t) {
    return dt(e, t, t);
  };
}, Jy = function() {
  return function(e) {
    return ft(e);
  };
}, Yy = function(e) {
  return function() {
    return ft(e);
  };
}, R = function(e) {
  return y(t_(Xy()), fe(function(t) {
    var r = t[0], n = t[1];
    return e(r) ? $e(r) : Yy(n);
  }));
}, j = function(e, t) {
  return function(r) {
    return y(e(r), nr(function(n) {
      return By(n, [t]);
    }));
  };
}, Xy = function() {
  return function(e) {
    return y(Qy(e), qr(function() {
      return ft(e);
    }, function(t) {
      var r = t.value, n = t.next;
      return dt(r, n, e);
    }));
  };
}, e_ = function(e, t) {
  return function(r) {
    return y(e(r), zn(function(n) {
      return y(t(n.value)(n.next), zn(function(s) {
        return dt(s.value, s.next, r);
      }));
    }));
  };
}, ar = function(e, t) {
  return function(r) {
    var n = e(r);
    return Hy(n) || n.left.fatal ? n : y(t()(r), nr(function(s) {
      return Gy(n.left, s);
    }));
  };
}, t_ = function(e) {
  return function(t) {
    return y(e(t), lt(function(r) {
      return ne(ne({}, r), { value: [r.value, t] });
    }));
  };
}, Ds = function(e) {
  return pe(function() {
    return $e(e.empty);
  });
}, zr = function() {
  return j(function(e) {
    return Wy(e) ? dt(void 0, e, e) : ft(e);
  }, "end of file");
}, r_ = function(e) {
  return y(Fr(e), pe(function() {
    return $e([]);
  }));
}, Fr = function(e) {
  return y(e, fe(function(t) {
    return Wr(Oy(t), function(r) {
      return y(e, k(function(n) {
        return se(Cy(n)(r));
      }), pe(function() {
        return $e(z(r));
      }));
    });
  }));
}, n_ = function(e, t) {
  return function(r) {
    return y(e, fe(function() {
      return r;
    }), Qr(function() {
      return t;
    }));
  };
}, Ls = function(e) {
  return n_(e, e);
}, a_ = function(e) {
  return r_(R(ae(e)));
}, Kr = function(e, t) {
  return y(t, k(function() {
    return ky;
  }), pe(function() {
    return s_(e, t);
  }));
}, s_ = function(e, t) {
  return y(e, fe(function(r) {
    return Wr(Ai(r), function(n) {
      return y(t, k(function() {
        return z(n);
      }), pe(function() {
        return y(e, k(function(s) {
          return se(Sy(s)(n));
        }));
      }));
    });
  }));
}, pt = function(e, t) {
  return function(r) {
    return y(e(r), lt(function(n) {
      return ne(ne({}, n), { value: t(n.value) });
    }));
  };
}, Us = function(e, t) {
  return sr(e, function(r) {
    return pt(t, r);
  });
}, sr = function(e, t) {
  return e_(e, t);
}, Wr = function(e, t) {
  var r = function(n) {
    return function(s) {
      return Te(s.value) ? se({ value: s.value.left, stream: s.next }) : z(dt(s.value.right, s.next, n));
    };
  };
  return function(n) {
    return Uy({ value: e, stream: n }, function(s) {
      var i = t(s.value)(s.stream);
      return Te(i) ? z(ft(s.stream, i.left.expected, i.left.fatal)) : r(n)(i.right);
    });
  };
}, i_ = function(e, t) {
  return ar(e, t);
}, k = function(e) {
  return function(t) {
    return pt(t, e);
  };
}, $e = Gr, fe = function(e) {
  return function(t) {
    return sr(t, e);
  };
}, Qr = function(e) {
  return function(t) {
    return sr(t, function(r) {
      return pt(e(r), function() {
        return r;
      });
    });
  };
}, pe = function(e) {
  return function(t) {
    return i_(t, e);
  };
}, o_ = "Parser", c_ = function(e) {
  return {
    concat: function(t, r) {
      return Us(pt(t, function(n) {
        return function(s) {
          return e.concat(n, s);
        };
      }), r);
    }
  };
}, u_ = function(e) {
  return ne(ne({}, c_(e)), { empty: Gr(e.empty) });
}, l_ = {
  URI: o_,
  map: pt,
  ap: Us,
  chain: sr,
  chainRec: Wr
}, Zs = function(e, t, r) {
  var n;
  return Object.assign({}, e, (n = {}, n[t] = r, n));
}, Hs = function(e) {
  return function(t) {
    return y(t, k(function(r) {
      return Zs({}, e, r);
    }));
  };
}, Ar = function(e, t) {
  return function(r) {
    return y(r, fe(function(n) {
      return y(t(n), k(function(s) {
        return Zs(n, e, s);
      }));
    }));
  };
}, d_ = function(e) {
  return yi(e)(e.empty);
}, f_ = Ds(wr), Ae = function(e) {
  return j(R(function(t) {
    return t === e;
  }), '"'.concat(e, '"'));
}, p_ = function(e) {
  return j(R(function(t) {
    return t !== e;
  }), 'anything but "'.concat(e, '"'));
}, Jr = function(e) {
  return f_(ht(e));
}, ht = function(e) {
  return y(Fr(e), k(function(t) {
    return t.join("");
  }));
}, Yr = function(e) {
  return "0123456789".indexOf(e) !== -1;
}, Sr = j(R(Yr), "a digit"), h_ = /^\s$/, qs = function(e) {
  return h_.test(e);
}, Bs = j(R(qs), "a whitespace"), v_ = function(e) {
  return e === "_";
}, vt = function(e) {
  return /[a-z]/.test(e.toLowerCase());
}, Gs = function(e) {
  return vt(e) || Yr(e) || v_(e);
};
j(R(Gs), "a word character");
j(R(vt), "a letter");
var m_ = function(e) {
  return e.toLowerCase() !== e.toUpperCase();
};
j(R(m_), "an unicode letter");
var zs = function(e) {
  return vt(e) && e === e.toUpperCase();
};
j(R(zs), "an upper case letter");
var Fs = function(e) {
  return vt(e) && e === e.toLowerCase();
};
j(R(Fs), "a lower case letter");
j(R(ae(Yr)), "a non-digit");
var Ks = j(R(ae(qs)), "a non-whitespace character");
j(R(ae(Gs)), "a non-word character");
j(R(ae(vt)), "a non-letter character");
j(R(ae(zs)), "anything but an upper case letter");
j(R(ae(Fs)), "anything but a lower case letter");
var Ws = function(e) {
  return j(l_.chainRec(e, function(t) {
    return y(__(0, t), qr(function() {
      return $e(z(e));
    }, function(r) {
      return y(Ae(r), fe(function() {
        return $e(se(t.slice(1)));
      }));
    }));
  }), JSON.stringify(e));
}, Er = d_(u_(wr)), rt = Ds(wr), g_ = function(e) {
  return rt(y_(e));
}, y_ = function(e) {
  return y(Fr(e), k(function(t) {
    return t.join("");
  }));
}, __ = function(e, t) {
  return e < t.length ? ut(t.charAt(e)) : rr;
}, b_ = Jr(Bs);
ht(Bs);
Jr(Ks);
ht(Ks);
var T_ = function(e) {
  var t = +e;
  return isNaN(t) || e === "" ? rr : ut(t);
};
j(y(Er([rt(Ae("-")), ht(Sr)]), k(function(e) {
  return +e;
})), "an integer");
j(y(Er([rt(Ae("-")), Jr(Sr), rt(Er([Ae("."), ht(Sr)]))]), fe(function(e) {
  return y(T_(e), qr(function() {
    return Jy();
  }, Gr));
})), "a float");
var $_ = Ls(Ae('"'))(g_(ar(Ws('\\"'), function() {
  return p_('"');
})));
function Qs(e) {
  return function(t) {
    return t(Fy(e.split("")));
  };
}
const Fn = (e, t) => (r) => ({
  ...r,
  [e]: t(r[e])
}), A_ = ["#", ":"], Se = Ls(b_), Xr = (e) => (t) => t.join(e), en = (e) => y(
  a_((t) => e.includes(t)),
  k(Xr(""))
), S_ = Y(
  en,
  Qr(() => R(() => !0))
), Js = y(
  $_,
  k((e) => JSON.parse(`"${e}"`))
), E_ = y(
  Se(Js),
  pe(
    () => y(
      en([":", `
`]),
      k(ta)
    )
  )
), w_ = y(
  Se(Js),
  pe(
    () => y(
      en([`
`]),
      k(ta)
    )
  )
), Ys = y(
  rt(Ws("#")),
  k(ae(ra))
), Xs = y(
  Se(Ys),
  Hs("commented"),
  Ar("key", () => Se(E_)),
  Qr(() => Ae(":")),
  Ar("value", () => w_)
), x_ = y(
  Se(Ys),
  Hs("commented"),
  Ar("key", () => ar(
    S_([`
`]),
    () => y(
      Kr(R((e) => !0), zr()),
      k(Y(
        Ns,
        Xr("")
      ))
    )
  )),
  k(Y(
    Ps(({ key: e }) => !ra(e))
  ))
), k_ = y(
  Kr(Se(Xs), zr())
), j_ = y(
  Kr(
    ar(
      y(Xs, k(ut)),
      () => y(
        x_,
        k(Y(
          $r((e) => ({ ...e, value: "" }))
        ))
      )
    ),
    zr()
  ),
  k(Y(
    xy(Y(
      Ps(Vy),
      $r((e) => e.value)
    ))
  ))
), R_ = (e) => {
  if (A_.some((n) => e.includes(n)))
    return !0;
  const t = JSON.stringify(e);
  return t.substring(1, t.length - 1).trim() !== e;
}, Kn = (e) => R_(e) ? JSON.stringify(e) : e, Q_ = (e) => y(
  e,
  My(
    Y(
      Fn("key", Kn),
      Fn("value", Kn),
      ({ key: t, value: r, active: n }) => n ? `${t}: ${r}` : `# ${t}: ${r}`
    )
  ),
  Xr(`
`)
), O_ = (e) => y(
  j_,
  Qs(e),
  nr((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  lt(
    ({ value: t }) => y(
      t,
      Ms(
        ({ key: r, value: n, commented: s }) => ({
          active: !s,
          key: r,
          value: n
        })
      )
    )
  )
), J_ = (e) => y(
  k_,
  Qs(e),
  nr((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  lt(
    ({ value: t }) => y(
      t,
      Ms(
        ({ key: r, value: n, commented: s }) => ({
          active: !s,
          key: r,
          value: n
        })
      )
    )
  )
), Y_ = Y(
  O_,
  lt(Ns),
  Br(() => [])
), C_ = a.object({
  id: a.optional(a.string()),
  name: a.string(),
  variables: a.array(
    a.object({
      key: a.string(),
      value: a.string()
    })
  )
}), Wn = S({
  initial: !0,
  schema: C_
}), ei = () => Math.random().toString(36).substring(2, 16), I_ = a.object({
  v: a.literal(1),
  id: a.string(),
  name: a.string(),
  variables: a.array(
    a.union([
      a.object({
        key: a.string(),
        secret: a.literal(!0)
      }),
      a.object({
        key: a.string(),
        value: a.string(),
        secret: a.literal(!1).catch(!1)
      })
    ])
  )
}), M_ = S({
  initial: !1,
  schema: I_,
  up(e) {
    return {
      ...e,
      v: 1,
      id: e.id || ei(),
      variables: e.variables.map((r) => ({
        ...r,
        secret: !1
      }))
    };
  }
}), ti = [
  // Common
  {
    key: "$guid",
    description: "A v4 style GUID.",
    getValue: () => {
      const e = "0123456789abcdef";
      let t = "";
      for (let r = 0; r < 36; r++)
        r === 8 || r === 13 || r === 18 || r === 23 ? t += "-" : r === 14 ? t += "4" : r === 19 ? t += e.charAt(8 + Math.floor(Math.random() * 4)) : t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  {
    key: "$timestamp",
    description: "The current UNIX timestamp in seconds.",
    getValue: () => Math.floor(Date.now() / 1e3).toString()
  },
  {
    key: "$isoTimestamp",
    description: "The current ISO timestamp at zero UTC.",
    getValue: () => (/* @__PURE__ */ new Date()).toISOString()
  },
  {
    key: "$randomUUID",
    description: "A random 36-character UUID.",
    getValue: () => {
      const e = "0123456789abcdef";
      let t = "";
      for (let r = 0; r < 36; r++)
        r === 8 || r === 13 || r === 18 || r === 23 ? t += "-" : t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  // Text, numbers, and colors
  {
    key: "$randomAlphaNumeric",
    description: "A random alpha-numeric character.",
    getValue: () => {
      const e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      return e.charAt(Math.floor(Math.random() * e.length));
    }
  },
  {
    key: "$randomBoolean",
    description: "A random boolean value.",
    getValue: () => Math.random() < 0.5 ? "true" : "false"
  },
  {
    key: "$randomInt",
    description: "A random integer between 0 and 1000.",
    getValue: () => Math.floor(Math.random() * 1e3).toString()
  },
  {
    key: "$randomColor",
    description: "A random color.",
    getValue: () => {
      const e = ["red", "green", "blue", "yellow", "purple", "orange"];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomHexColor",
    description: "A random hex value.",
    getValue: () => {
      const e = "0123456789abcdef";
      let t = "#";
      for (let r = 0; r < 6; r++)
        t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  {
    key: "$randomAbbreviation",
    description: "A random abbreviation.",
    getValue: () => {
      const e = [
        "SQL",
        "PCI",
        "JSON",
        "HTML",
        "CSS",
        "JS",
        "TS",
        "API"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  // Internet and IP addresses
  {
    key: "$randomIP",
    description: "A random IPv4 address.",
    getValue: () => Array.from(
      { length: 4 },
      () => Math.floor(Math.random() * 256)
    ).join(".")
  },
  {
    key: "$randomIPV6",
    description: "A random IPv6 address.",
    getValue: () => Array.from(
      { length: 8 },
      () => Math.floor(Math.random() * 65536).toString(16)
    ).join(":")
  },
  {
    key: "$randomMACAddress",
    description: "A random MAC address.",
    getValue: () => Array.from(
      { length: 6 },
      () => Math.floor(Math.random() * 256).toString(16)
    ).join(":")
  },
  {
    key: "$randomPassword",
    description: "A random 15-character alpha-numeric password.",
    getValue: () => {
      const e = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let t = "";
      for (let r = 0; r < 15; r++)
        t += e.charAt(
          Math.floor(Math.random() * e.length)
        );
      return t;
    }
  },
  {
    key: "$randomLocale",
    description: "A random two-letter language code (ISO 639-1).",
    getValue: () => {
      const e = ["ny", "sr", "si"];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomUserAgent",
    description: "A random user agent.",
    getValue: () => {
      const e = [
        "Mozilla/5.0 (Macintosh; U; Intel Mac OS X 10.9.8; rv:15.6) Gecko/20100101 Firefox/15.6.6",
        "Mozilla/5.0 (Windows NT 6.1; WOW64; rv:15.6) Gecko/20100101 Firefox/15.6.6",
        "Mozilla/5.0 (Windows NT 6.1; WOW64; rv:15.6) Gecko/20100101 Firefox/15.6.6"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomProtocol",
    description: "A random internet protocol.",
    getValue: () => {
      const e = ["http", "https"];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomSemver",
    description: "A random semantic version number.",
    getValue: () => Array.from(
      { length: 3 },
      () => Math.floor(Math.random() * 10)
    ).join(".")
  },
  // Names
  {
    key: "$randomFirstName",
    description: "A random first name.",
    getValue: () => {
      const e = [
        "Ethan",
        "Chandler",
        "Megane",
        "John",
        "Jane",
        "Alice",
        "Bob"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomLastName",
    description: "A random last name.",
    getValue: () => {
      const e = [
        "Schaden",
        "Schneider",
        "Willms",
        "Doe",
        "Smith",
        "Johnson"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomFullName",
    description: "A random first and last name.",
    getValue: () => {
      const e = [
        "Ethan",
        "Chandler",
        "Megane",
        "John",
        "Jane",
        "Alice",
        "Bob"
      ], t = [
        "Schaden",
        "Schneider",
        "Willms",
        "Doe",
        "Smith",
        "Johnson"
      ];
      return `${e[Math.floor(Math.random() * e.length)]} ${t[Math.floor(Math.random() * t.length)]}`;
    }
  },
  {
    key: "$randomNamePrefix",
    description: "A random name prefix.",
    getValue: () => {
      const e = ["Dr.", "Ms.", "Mr.", "Mrs.", "Miss", "Prof."];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomNameSuffix",
    description: "A random name suffix.",
    getValue: () => {
      const e = ["I", "MD", "DDS", "PhD", "Esq.", "Jr."];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  // Addresses
  {
    key: "$randomCity",
    description: "A random city name.",
    getValue: () => {
      const e = [
        "New York",
        "Los Angeles",
        "Chicago",
        "Houston",
        "Phoenix",
        "Philadelphia"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  // profession
  {
    key: "$randomJobArea",
    description: "A random job area.",
    getValue: () => {
      const e = [
        "Mobility",
        "Intranet",
        "Configuration",
        "Development",
        "Design",
        "Testing"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomJobDescriptor",
    description: "A random job descriptor.",
    getValue: () => {
      const e = [
        "Forward",
        "Corporate",
        "Senior",
        "Junior",
        "Lead",
        "Principal"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomJobTitle",
    description: "A random job title.",
    getValue: () => {
      const e = [
        "International Creative Liaison",
        "Global Branding Officer",
        "Dynamic Data Specialist",
        "Internal Communications Consultant",
        "Productivity Analyst",
        "Regional Applications Developer"
      ];
      return e[Math.floor(Math.random() * e.length)];
    }
  },
  {
    key: "$randomJobType",
    description: "A random job type.",
    getValue: () => {
      const e = ["Supervisor", "Manager", "Coordinator", "Director"];
      return e[Math.floor(Math.random() * e.length)];
    }
  }
  // TODO: Support various other predefined variables
], N_ = a.object({
  v: a.number()
}), X_ = st({
  latestVersion: 1,
  versionMap: {
    0: Wn,
    1: M_
  },
  getVersion(e) {
    const t = N_.safeParse(e);
    return t.success ? t.data.v : Wn.schema.safeParse(e).success ? 0 : null;
  }
}), Nt = /<<([^>]*)>>/g, Pt = 10, ri = "ENV_EXPAND_LOOP", Qn = 1;
function P_(e, t) {
  let r = e, n = 0;
  for (; r.match(Nt) != null && n <= Pt; )
    r = r.replace(Nt, (s) => {
      const i = s.replace(/[<>]/g, ""), o = ti.find(
        (u) => u.key === i
      );
      if (o)
        return o.getValue();
      const c = t.find((u) => u.key === i);
      return c && "value" in c ? c.value : s;
    }), n++;
  return n > Pt ? se(ri) : z(r);
}
const eb = (e, t) => y(
  P_(e, t),
  Br(() => e)
);
function V_(e, t, r = !1, n = !1) {
  if (!t || !e)
    return z(e);
  let s = e, i = 0, o = !1;
  for (; s.match(Nt) != null && i <= Pt && !o; )
    s = decodeURI(encodeURI(s)).replace(Nt, (c, u) => {
      const l = ti.find(
        (_) => _.key === u
      );
      if (l)
        return l.getValue();
      const f = t.find((_) => _ && _.key === u);
      return f && "value" in f ? f.secret && n ? (o = !0, `<<${u}>>`) : f.secret && r ? "*".repeat(
        f.value.length
      ) : f.value : "";
    }), i++;
  return i > Pt ? se(ri) : z(s);
}
const tb = (e, t, r = !1, n = !1) => y(
  V_(e, t, r, n),
  Br(() => e)
), D_ = (e) => ({
  key: e.key,
  value: e.value,
  secret: !1
}), rb = (e) => {
  if (e.v && e.v === Qn) return e;
  const t = e.id || ei(), r = e.name ?? "Untitled", n = (e.variables ?? []).map(D_);
  return {
    v: Qn,
    id: t,
    name: r,
    variables: n
  };
}, L_ = a.array(
  a.union([
    a.object({
      key: a.string(),
      value: a.string(),
      secret: a.literal(!1)
    }),
    a.object({
      key: a.string(),
      secret: a.literal(!0)
    }),
    a.object({
      key: a.string(),
      value: a.string()
    })
  ])
), Jn = S({
  initial: !0,
  schema: L_
}), U_ = a.object({
  v: a.literal(1),
  variables: a.array(
    a.union([
      a.object({
        key: a.string(),
        secret: a.literal(!0)
      }),
      a.object({
        key: a.string(),
        value: a.string(),
        secret: a.literal(!1)
      })
    ])
  )
}), Z_ = S({
  initial: !1,
  schema: U_,
  up(e) {
    return {
      v: 1,
      variables: e.map((r) => "value" in r ? {
        key: r.key,
        value: r.value,
        secret: !1
      } : {
        key: r.key,
        secret: !0
      })
    };
  }
}), H_ = a.object({
  v: a.number()
}), nb = st({
  latestVersion: 1,
  versionMap: {
    0: Jn,
    1: Z_
  },
  getVersion(e) {
    const t = H_.safeParse(e);
    return t.success ? t.data.v : Jn.schema.safeParse(e).success ? 0 : null;
  }
});
export {
  Ka as AuthCodeGrantTypeParams,
  kg as ClientCredentialsGrantTypeParams,
  Ty as CollectionSchemaVersion,
  X_ as Environment,
  Qn as EnvironmentSchemaVersion,
  is as FormDataKeyValue,
  ys as GQLHeader,
  $s as GQL_REQ_SCHEMA_VERSION,
  nb as GlobalEnvironment,
  ti as HOPP_SUPPORTED_PREDEFINED_VARIABLES,
  W_ as HoppCollection,
  Ts as HoppGQLAuth,
  tr as HoppGQLAuthAPIKey,
  gs as HoppGQLAuthAWSSignature,
  Pe as HoppGQLAuthBasic,
  Ve as HoppGQLAuthBearer,
  De as HoppGQLAuthInherit,
  Ne as HoppGQLAuthNone,
  es as HoppGQLAuthOAuth2,
  Hr as HoppGQLRequest,
  ct as HoppRESTAuth,
  za as HoppRESTAuthAPIKey,
  Lr as HoppRESTAuthAWSSignature,
  Ce as HoppRESTAuthBasic,
  Ie as HoppRESTAuthBearer,
  Og as HoppRESTAuthDigest,
  Me as HoppRESTAuthInherit,
  Oe as HoppRESTAuthNone,
  Rg as HoppRESTAuthOAuth2,
  ot as HoppRESTHeaders,
  Xt as HoppRESTParams,
  ls as HoppRESTReqBody,
  er as HoppRESTRequest,
  Vg as HoppRESTRequestResponse,
  cs as HoppRESTRequestResponses,
  Ft as HoppRESTRequestVariables,
  Pg as HoppRESTResponseOriginalRequest,
  Qt as ImplicitOauthFlowParams,
  jg as PasswordGrantTypeParams,
  ds as RESTReqSchemaVersion,
  q_ as ValidContentTypesList,
  Rs as generateUniqueRefId,
  oy as getDefaultGQLRequest,
  Bg as getDefaultRESTRequest,
  B_ as isEqualHoppRESTRequest,
  F_ as isHoppRESTRequest,
  Zg as knownContentTypes,
  Cs as makeCollection,
  K_ as makeGQLRequest,
  z_ as makeRESTRequest,
  eb as parseBodyEnvVariables,
  P_ as parseBodyEnvVariablesE,
  Y_ as parseRawKeyValueEntries,
  O_ as parseRawKeyValueEntriesE,
  tb as parseTemplateString,
  V_ as parseTemplateStringE,
  Q_ as rawKeyValueEntriesToString,
  G_ as safelyExtractRESTRequest,
  J_ as strictParseRawKeyValueEntriesE,
  cy as translateToGQLRequest,
  rb as translateToNewEnvironment,
  D_ as translateToNewEnvironmentVariables,
  Ay as translateToNewGQLCollection,
  $y as translateToNewRESTCollection,
  Gg as translateToNewRequest
};
